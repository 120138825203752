<template>
    <div>
        <v-card style="height: 100%">
            <DynamicSmoothLineChart :key="refreshKey" :title="'TUM V3 API Throttles'" :updateKey="refreshKey"
                :color="'green'" defaultChart="line" xAxisLabel="Timestamps" yAxisLabel="Count" :textColor="'grey'"
                :data="throttlesChart.data" :labels="throttlesChart.chartOptions.xaxis.categories"></DynamicSmoothLineChart>
        </v-card>
    </div>
</template>

<script>
import DynamicSmoothLineChart from "../../../Charts/TUMChart.vue";
export default {
    components: {
        DynamicSmoothLineChart,
    },
    props: {
        widget: {
            type: String,
            required: true,
        },
        columns: {
            type: Number,
            required: true,
        },
        rows: {
            type: Number,
            required: true,
        },
        widgetIndex: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        // w and h are the default number of columns and rows respectively that the widget takes up. 
        // Changing these values will not affect the default size as the size is stored in the widget itself. 
        // To change default size, either edit the widget in the Widget Dev Tools or change it in the widget.json file found in /server/src/static/widgets
        w: 1,
        h: 1,

        // cellWidth and cellHeight are the actual widths and heights respectively of a cell in the grid, based on the users viewport size
        // These values are calculated and changed if the grid resizes, for instance if the right bar opens or if the navbar opens.
        cellWidth: 0,
        cellHeight: 0,
        refreshKey: 0,

        throttlesChart: {
            data: [{
                data: [],
                color: 'green',
                type: "line",
                smooth: true,
                showInLegend: true,
            },],
            chartOptions: {
                chart: {
                    id: 'stock-chart',
                    toolbar: {
                        show: false, // Hide the toolbar
                    },
                },
                xaxis: {
                    categories: [],
                },
                title: {
                    text: 'Change in Stock - May 2023',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: 'white', // change the color to white
                    },
                },
                tooltip: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,
                    style: {
                        fontSize: '12px',
                        fontFamily: undefined
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                    x: {
                        show: true,
                        format: 'dd MMM',
                        formatter: undefined,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: 'Size: '
                    },
                    marker: {
                        show: true,
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }
            },
        },
    }),
    mounted() {
        // DO NOT CHANGE THE CODE BELOW!!!
        // This code is used to calculate the number of columns and rows the widget takes up when it is resized
        // This is set up here because the sizes do not update dynamically from the parent component when the widget is resized
        // We need these values to resize / reorder the contents of the widget when it is resized
        // This also takes into account the resizing of the grid itself
        this.$nextTick(() => {
            const path = this.widget
            const grid = document.getElementById('grid');
            const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
            let gridWidth = grid.offsetWidth;
            let gridHeight = grid.offsetHeight;
            let cellWidth = gridWidth / 25;
            this.cellWidth = cellWidth;
            let cellHeight = gridHeight / 14;
            this.cellHeight = cellHeight;
            const gridResizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    gridWidth = entry.contentRect.width;
                    gridHeight = entry.contentRect.height;
                    cellWidth = gridWidth / 25;
                    this.cellWidth = cellWidth;
                    cellHeight = gridHeight / 14;
                    this.cellHeight = cellHeight;
                }
            });
            gridResizeObserver.observe(grid, {
                box: 'content-box'
            });
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const widgetWidth = entry.contentRect.width;
                    const widgetHeight = entry.contentRect.height;
                    const widgetColumns = Math.floor(widgetWidth / cellWidth);
                    this.w = widgetColumns;
                    const widgetRows = Math.floor(widgetHeight / cellHeight);
                    this.h = widgetRows;
                    console.log(`Widget: ${widgetColumns} cols x ${widgetRows} rows`);
                    this.refreshKey++
                }
            });
            resizeObserver.observe(widget, {
                box: 'content-box'
            });
        });
        // DO NOT CHANGE THE CODE ABOVE!!!
    },
    watch: {
        columns() {
            this.w = this.columns;
            this.refreshKey++
        },
        rows() {
            this.h = this.rows;
            this.refreshKey++
        },
    },
    computed: {

    },
    methods: {
        async getThrottles() {
            let response = await this.$API.getMetrics({
                params: {
                    metricName: "Throttles"
                }
            })

            let datapoints = response[0].Datapoints

            this.throttlesChart.data[0].data = datapoints.map((item) => {
                return item.Sum
            })
            this.throttlesChart.chartOptions.xaxis.categories = datapoints.map((item) => {
                return item.Timestamp
            })

            this.refreshKey++
        },
    },
    created() {
        this.getThrottles()
    }
};
</script>

<style scoped></style>
