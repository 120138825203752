<template>
<!--        JUST COPY THE CONTENTS OF THE FILE!!!       -->
<div>
    <v-card style="height: 100%">
        <v-toolbar flat>
            <v-toolbar-title>
                Trade Unit Events
            </v-toolbar-title>
        <v-spacer></v-spacer>
        </v-toolbar>

        <v-data-table fixed-header height="360" :items="result" :headers="headers" :key="updateKey" dense>
        </v-data-table>
    </v-card>
</div>
</template>

<script>
export default {
    props: {
        widget: {
            type: String,
            required: true,
        },
        columns: {
            type: Number,
            required: true,
        },
        rows: {
            type: Number,
            required: true,
        },
        widgetIndex: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        // w and h are the default number of columns and rows respectively that the widget takes up. 
        // Changing these values will not affect the default size as the size is stored in the widget itself. 
        // To change default size, either edit the widget in the Widget Dev Tools or change it in the widget.json file found in /server/src/static/widgets
        w: 1,
        h: 1,

        // cellWidth and cellHeight are the actual widths and heights respectively of a cell in the grid, based on the users viewport size
        // These values are calculated and changed if the grid resizes, for instance if the right bar opens or if the navbar opens.
        cellWidth: 0,
        cellHeight: 0,
        result: [],
        headers: [{
                text: "Vendor",
                value: "Vendor"
            },
            {
                text: "Version",
                value: "Version"
            },
            {
                text: "Count",
                value: "Count"
            },
            
        ],
        updateKey: 0,
    }),
    mounted() {
        // DO NOT CHANGE THE CODE BELOW!!!
        // This code is used to calculate the number of columns and rows the widget takes up when it is resized
        // This is set up here because the sizes do not update dynamically from the parent component when the widget is resized
        // We need these values to resize / reorder the contents of the widget when it is resized
        // This also takes into account the resizing of the grid itself
        this.$nextTick(() => {
            const path = this.widget
            const grid = document.getElementById('grid');
            const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
            let gridWidth = grid.offsetWidth;
            let gridHeight = grid.offsetHeight;
            let cellWidth = gridWidth / 25;
            this.cellWidth = cellWidth;
            let cellHeight = gridHeight / 14;
            this.cellHeight = cellHeight;
            const gridResizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    gridWidth = entry.contentRect.width;
                    gridHeight = entry.contentRect.height;
                    cellWidth = gridWidth / 25;
                    this.cellWidth = cellWidth;
                    cellHeight = gridHeight / 14;
                    this.cellHeight = cellHeight;
                }
            });
            gridResizeObserver.observe(grid, {
                box: 'content-box'
            });
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const widgetWidth = entry.contentRect.width;
                    const widgetHeight = entry.contentRect.height;
                    const widgetColumns = Math.round(widgetWidth / cellWidth);
                    this.w = widgetColumns;
                    const widgetRows = Math.round(widgetHeight / cellHeight);
                    this.h = widgetRows;
                    console.log(`Widget: ${widgetColumns} cols x ${widgetRows} rows`);
                }
            });
            resizeObserver.observe(widget, {
                box: 'content-box'
            });
        });
        // DO NOT CHANGE THE CODE ABOVE!!!
    },
    watch: {
        columns() {
            this.w = this.columns;
            this.updateKey++
        },
        rows() {
            this.h = this.rows;
            this.updateKey++
        },
    },
    computed: {

    },
    methods: {
        async loadEvents() {
            let response = await this.$API.getTUMVendorTUs();

            this.result = response.result;

            console.log({
                result: this.result
            })
            this.updateKey++
        }
    },
    created() {
        this.loadEvents()
    }
};
</script>  

<style scoped>

</style>
