<template>
    <div>
        <v-card style="height: inherit; width: inherit; overflow: scroll; background-color: var(--v-toolbar-lighten1) !important;">
            <v-toolbar dense flat class="tooly">
                <v-chip v-if="fivePlus" small color="redPop" @click="switchFilter('fivePlus')" class="mr-1 px-1">
                    <span class="mr-2">5+ Days:</span>
                    <v-chip x-small class="px-1" color="white" style="color: var(--v-redPop-base) !important">{{ daysCalculated.fivePlus  }}</v-chip>
                </v-chip>
                <v-chip v-if="threeToFour" small color="#dfaa49" @click="switchFilter('threeToFour')" class="mr-1 px-1">
                    <span class="mr-2">3-4 Days:</span>
                    <v-chip x-small class="px-1" color="white" style="color: #dfaa49 !important">{{ daysCalculated.threeToFour }}</v-chip>
                </v-chip>
                <v-chip v-if="zeroToTwo" small color="tertiary" @click="switchFilter('zeroToTwo')" class="mr-1 px-1">
                    <span class="mr-2">0-2 Days:</span>
                    <v-chip x-small class="px-1" color="white" style="color: var(--v-tertiary-base) !important">{{ daysCalculated.zeroToTwo }}</v-chip>
                </v-chip>
                <v-chip v-if="zeroToThree" small color="tertiary" @click="switchFilter('zeroToThree')" class="mr-1 px-1">
                    <span class="mr-2">Arriving:</span>
                    <v-chip x-small class="px-1" color="white" style="color: var(--v-tertiary-base) !important">{{ daysCalculated.zeroToThree }}</v-chip>
                </v-chip>
                <v-chip v-if="past" small color="grey" @click="switchFilter('past')" class="mr-1 px-1">
                    <span class="mr-2">Not Sailed</span>
                    <v-chip x-small class="px-1" color="white" style="color: var(--v-component-base) !important;">{{ daysCalculated.past }}</v-chip>
                </v-chip>
                <v-chip v-if="rejected" small class="px-1" color="#eb4034"  @click="switchFilter('rejected')">
                    <span class="mr-2">Rejected:</span>
                    <v-chip x-small class="px-1" color="white" style="color: #eb4034 !important">
                        {{ rejectedCount }}
                    </v-chip>
                </v-chip>
                <v-chip v-if="overdue" small class="px-1" color="#e37169" @click="switchFilter('overdue')">
                    <span class="mr-2">Overdue:</span>
                    <v-chip x-small class="px-1" color="white" style="color: #e37169 !important">
                        {{ overdueCount }}
                    </v-chip>
                </v-chip>
                <v-chip v-if="inDanger" small class="px-1" color="#dfaa49"  @click="switchFilter('inDanger')">
                    <span class="mr-2">In Danger:</span>
                    <v-chip x-small class="px-1" color="white" style="color: #dfaa49 !important">
                        {{ inDangerCount }}
                    </v-chip>
                </v-chip>
                <v-chip v-if="withinKPI" small class="px-1" color="#007d5c"  @click="switchFilter('withinKPI')">
                    <span class="mr-2">Within KPI:</span>
                <v-chip x-small class="px-1" color="white" style="color: #007d5c !important">
                    {{ withinKPICount }}
                </v-chip>
                </v-chip>
                <v-spacer></v-spacer>
                <v-chip :class="searchList ? 'px-1 searchChipWrap' : 'px-1 searchChipWrapSmall'">
                    <v-menu bottom offset-y :close-on-content-click="false">
                        <template v-slot:activator="{on, attrs}">
                            <v-btn v-on="on" v-bind="attrs" icon small ><v-icon>filter_alt</v-icon></v-btn>
                        </template>
                        <v-card color="greyRaised">
                            <v-card-text class="pt-0">
                                <v-subheader class="px-0">
                                    Days Since Sailed
                                </v-subheader>
                                <v-chip small :outlined="!fivePlus" color="redPop" @click="switchFilter('fivePlus')" class="mr-1 px-1">
                                    <span class="mr-2">5+ Days:</span>
                                    <v-chip x-small class="px-1" :color="!fivePlus ? 'redPop' : 'white'" :style="fivePlus ? {'color' : 'var(--v-redPop-base) !important'} : ''">{{ daysCalculated.fivePlus  }}</v-chip>
                                </v-chip>
                                <v-chip small :outlined="!threeToFour" color="#dfaa49" @click="switchFilter('threeToFour')" class="mr-1 px-1">
                                    <span class="mr-2">3-4 Days:</span>
                                    <v-chip x-small class="px-1" :color="!threeToFour ? '#dfaa49' : 'white'" :style="threeToFour ? {'color' : '#dfaa49 !important'} : ''">{{ daysCalculated.threeToFour }}</v-chip>
                                </v-chip>
                                <v-chip small :outlined="!zeroToTwo" color="tertiary" @click="switchFilter('zeroToTwo')" class="mr-1 px-1">
                                    <span class="mr-2">0-2 Days:</span>
                                    <v-chip x-small class="px-1" :color="!zeroToTwo ? 'tertiary' : 'white'" :style="zeroToTwo ? {'color' : 'var(--v-tertiary-base) !important'} : ''">{{ daysCalculated.zeroToTwo }}</v-chip>
                                </v-chip>
                                <v-chip small :outlined="!zeroToThree" color="tertiary" @click="switchFilter('zeroToThree')" class="mr-1 px-1">
                                    <span class="mr-2">0-2 Days:</span>
                                    <v-chip x-small class="px-1" :color="!zeroToThree ? 'tertiary' : 'white'" :style="zeroToThree ? {'color' : 'var(--v-tertiary-base) !important'} : ''">{{ daysCalculated.zeroToThree }}</v-chip>
                                </v-chip>
                                <v-chip small :outlined="!past" color="grey" @click="switchFilter('past')" class="mr-1 px-1">
                                    <span class="mr-2">Not Sailed</span>
                                    <v-chip x-small class="px-1" :color="!past ? 'grey' : 'white'" style="color: var(--v-component-base) !important;">{{ daysCalculated.past }}</v-chip>
                                </v-chip>

                                <v-subheader class="px-0">
                                    Document Status
                                </v-subheader>
                                <v-chip class="pr-1 pl-2" :outlined="!rejected" :style="!rejected ? {'border': 'none'} : {'background-color': '#eb403456'}" @click="switchFilter('rejected')">
                                    <span class="mr-2" style="font-size: 12px">Rejected:</span>
                                    <v-chip small pill color="danger">{{ rejectedCount }}</v-chip>
                                </v-chip>
                                <v-chip class="pr-1 pl-2" :outlined="!overdue" :style="!overdue ? {'border': 'none'} : {'background-color': '#e371696c'}" @click="switchFilter('overdue')">
                                    <span class="mr-2" style="font-size: 12px">Overdue:</span>
                                    <v-chip small pill color="redPop">
                                        <b>{{ overdueCount }}</b>
                                    </v-chip>
                                </v-chip>
                                <v-chip class="pr-1 pl-2" :outlined="!inDanger" :style="!inDanger ? {'border': 'none'} : {'background-color': '#dfaa494f'}" @click="switchFilter('inDanger')">
                                    <span class="mr-2" style="font-size: 12px">In Danger:</span>
                                    <v-chip small pill color="#dfaa49">{{ inDangerCount }}</v-chip>
                                </v-chip>
                                <v-chip class="pr-1 pl-2" :outlined="!withinKPI" :style="!withinKPI ? {'border': 'none'} : {'background-color': '#007d5c52'}" @click="switchFilter('withinKPI')">
                                    <span class="mr-2" style="font-size: 12px">Within KPI:</span>
                                <v-chip small pill color="tertiary">{{ withinKPICount }}</v-chip>
                                </v-chip>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <v-btn icon small @click="searchList = !searchList"><v-icon>search</v-icon></v-btn>
                    <v-slide-x-reverse-transition>
                    <v-text-field
                        v-if="searchList"
                        placeholder="Search"
                        class="mb-1 searchInput"
                        hide-details
                        rounded
                        clearable
                        dense
                        style=" margin-top: 2px"
                        v-model="search"
                    ></v-text-field>
                    </v-slide-x-reverse-transition>
                </v-chip>
            </v-toolbar>
                <draggable
                class="headersSnap no-drag" 
                v-model="headers"
                tag="div"
                :style="{ cursor: columnDrag ? 'grabbing' : 'grab', gridTemplateColumns: gridTemplateColumns() }"
                :animation="200"
                ghost-class="ghost-card"
                @start="columnDrag = true"
                @end="columnDrag = false"
              >
                <div v-for="(header, index) in headersCols" :key="index" style="font-size: 12px;" :style="index == headersCols.length - 1 ? '' : {'border-right': '1px solid #ffffffad'}" class="d-flex justify-center">
                    <b>{{ header.name }}</b>
                    <v-btn v-if="!header.disableSort" class="ml-1" icon x-small @click="changeSortBy(header.value)">
                        <v-icon v-if="sortBy != header.value" small color="grey">arrow_upward</v-icon>
                        <v-icon v-else-if="sortDesc" small>arrow_downward</v-icon>
                        <v-icon v-else small>arrow_upward</v-icon>
                    </v-btn>
                    <v-badge
                        v-if="!header.disableFilter"
                        :content="
                            filters[header.value]
                            ? filters[header.value].length
                            : false
                        "
                        :value="
                            filters[header.value] &&
                            filters[header.value].length > 0
                        "
                        color="primary"
                        offset-y="10"
                    >
                        <v-menu offset-y left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                color="grey"
                                icon
                                ><v-icon small>filter_alt</v-icon></v-btn
                            >
                            </template>
                            <v-card
                            style="
                                background: var(--v-modal-base) !important;
                                border-radius: 20px;
                            "
                            >
                                <v-card-text>
                                    <v-list
                                    dense
                                    style="
                                        background: var(--v-modal-base) !important;
                                        border-radius: 20px;
                                    "
                                    >
                                    <v-text-field
                                        hide-details
                                        rounded
                                        dense
                                        outlined
                                        clearable
                                        prepend-inner-icon="search"
                                        placeholder="Search"
                                        autofocus
                                        v-model="searchHeaders[header.value]"
                                        @input="searchKey++;"
                                    ></v-text-field>
                                    <v-list
                                        dense
                                        style="
                                        max-height: 50vh;
                                        overflow-y: auto;
                                        background: var(--v-modal-base) !important;
                                        "
                                        :key="searchKey"
                                    >
                                        <v-list-item
                                        v-for="(item, index) in headerOptions(
                                            header.value
                                        )"
                                        :key="index"
                                        >
                                        <v-list-item-action class="pr-0">
                                            <v-checkbox
                                            v-model="filters[header.value]"
                                            :value="item.value"
                                            hide-details
                                            @change="calculateFilteredResults()"
                                            dense
                                            ></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                            {{ item.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                        v-if="
                                            headerOptions(header.value).length == 0
                                        "
                                        >
                                        <v-list-item-content class="text-center">
                                            <span style="font-size: 12px"
                                            >No results found</span
                                            >
                                        </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-badge>
                </div>
            </draggable>
            <div style="height: 2px;" class="my-1" v-if="!loadingFiles"></div>
            <v-progress-linear indeterminate style="height: 2px; " class="my-1" v-if="loadingFiles"></v-progress-linear>
            <v-virtual-scroll v-if="dataMap.length > 0" :bench="10" :items="dataMap" :height="calculatedHeight" width="100%" item-height="35" class="px-0" >
                <template v-slot:default="{ item}" >
                    <div class="headersSnap" :style="{gridTemplateColumns: gridTemplateColumns()}" >
                        <div v-for="(header, index) in headersCols" :key="index" :class="`d-flex justify-${header.align} cellContain`">
                            <div v-if="header.value == 'fileNumber'" class="text-center d-flex">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-on="on" small class="primaryText--text pl-0 pr-1" outlined style="border: none">
                                            <v-hover v-slot="{hover}">
                                                <v-avatar class="smallerAvatar mr-1" small @click="viewShipment(item)"
                                                    :color="item.daysFromToday >= 5 ? 'redPop' : 
                                                    (item.daysFromToday >= 3) && (5 > item.daysFromToday) ? '#dfaa49' : 
                                                    (item.daysFromToday >= 0) && (3 > item.daysFromToday) ? 'tertiary' :
                                                    0 > item.daysFromToday ? 'grey' : '' 
                                                    "
                                                >
                                                    <b style="color: white; font-size: 8px" v-if="!hover">
                                                        {{ 0 > item.daysFromToday ? '-' : item.daysFromToday }}
                                                    </b>
                                                    <v-icon v-if="hover" small>launch</v-icon>
                                                </v-avatar>
                                            </v-hover>
                                            <span @click="addToFilter(item[header.value], header.value)" class="file">
                                                {{ item.fileNumber }}
                                            </span>
                                        </v-chip>
                                    </template>
                                    <span>{{$Format.capitalizeFirstLetter(item.movementType)}} Shipment
                                    </span>
                                </v-tooltip>
                            </div>
                            <div v-else-if="header.value == 'document'" >
                                <div class="d-flex">
                                    <v-chip class="primaryText--text px-0" small outlined style="border:none; font-size:10px ;">
                                        <v-btn icon left small plain @click="viewDocument(item)" :loading="documentLoading == item.id">
                                            <v-icon small>launch</v-icon>
                                        </v-btn>
                                        <span class="file" @click="addToFilter(item[header.value], header.value)" :class="item.kpiCategory == 'OVERDUE' ? 'overdue' : item.kpiCategory == 'DANGER' ? 'inDanger' :item.kpiCategory == 'GOOD' ? 'withinKPI' : ''"
                                        >{{ item.document }}</span>
                                        <!-- <v-icon right small v-if="item.kpiCategory"
                                        :color="item.kpiCategory == 'OVERDUE' ? 'error' : item.kpiCategory == 'DANGER' ? 'warning' : 'success'"
                                        >
                                        {{ item.kpiCategory == 'OVERDUE' ? 'error' : item.kpiCategory == 'DANGER' ? 'warning' : 'check_circle' }}
                                        </v-icon> -->
                                    </v-chip>
                                </div>
                            </div>
                            <div v-else-if="header.value == 'status'">
                                <v-chip v-if="item.status" small class="primaryText--text" outlined style="border: none" @click="addToFilter(item.status, header.value)">
                                    <span :class="item.status == 'Rejected' ? 'rejected' : ''">{{ item.status }}</span>
                                </v-chip>
                            </div>
                            <div v-else-if="header.value == 'customerName'">
                                <v-tooltip top v-if="item.customerName">
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-if="item.customerName" small class="primaryText--text" v-on="on" outlined style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            <v-avatar size="14" class="smallerAvatar" :color="item.customer && item.customer.logo ? 'white' : 'secondary'" left>
                                                <v-img v-if="item.customer.logo" :src="item.customer.logo" contain></v-img>
                                                <h4 v-else style="color: white">
                                                    {{ item.customerName.charAt(0) }}
                                                </h4>
                                            </v-avatar>
                                            {{ removePtyLtd(item.customerName) }}
                                        </v-chip>
                                    </template>
                                    <span>{{ item.customerName }}</span>
                                </v-tooltip>
                            </div>
                            <div v-else-if="header.value == 'portOfLoadValue'" class="d-flex">
                                <v-tooltip top v-if="item.portOfLoadValue">
                                    <template v-slot:activator="{ on }">
                                        <v-chip class="pa-0" outlined style="border: none; font-size: 11px" v-on="on" small @click="addToFilter(item[header.value], header.value)">
                                            <v-avatar class="flagAvatar">
                                                <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                            </v-avatar>
                                            <span style="font-size: 10px">{{item.portOfLoadValue}}</span>
                                        </v-chip>
                                    </template>
                                    <span style="font-size: 12px">{{item.portOfLoadCity}}</span>
                                </v-tooltip>
                                <v-icon small>arrow_forward</v-icon>
                                <v-tooltip top v-if="item.finalDestinationValue">
                                    <template v-slot:activator="{ on }">
                                        <v-chip class="pa-0" outlined style="border: none; font-size: 11px" v-on="on" small @click="addToFilter(item[header.value], header.value)">
                                            <v-avatar class="flagAvatar">
                                                <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                            </v-avatar>
                                            <span style="font-size: 10px">{{item.finalDestinationValue}}</span>
                                        </v-chip>
                                    </template>
                                    <span style="font-size: 12px">{{item.finalDestinationCity}}</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </template>
            </v-virtual-scroll>
        </v-card>
        <v-dialog persistent v-model="documentModal" fullscreen>
            <DocumentModal @close="documentModal = false" :shipment="shipment" :item="shipment" :actions="actions"
                :bookings="bookings" :documentItem="document" :tasks="documentTasks" :documents="documentTypes"
                :documentTypes="documentTypes" :fileNumber="fileNumber" :uploads="documentUploads"
                :openKey="openKey" />
        </v-dialog>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import DocumentModal from "@/components/SystemComponents/DocumentModal.vue";
export default {
components: {
    draggable,
    DocumentModal,
},
props: { 
    widget: {
        type: String,
        required: true,
    },
    columns: {
        type: Number,
        required: true,
    },
    rows: {
        type: Number,
        required: true,
    }, 
    globalFilter: {
        type: Object,
        required: true,
    },
    widgetIndex: {
        type: Number,
        required: true,
    },
},
data: () => ({
    sortBy: null,
    sortDesc: null,
    fivePlus: false,
    threeToFour: false,
    zeroToTwo: false,
    zeroToThree:false,
    openFilesFilter: false,
    rejected: false,
    overdue: false,
    inDanger: false,
    withinKPI: false,
    past: false,
    daysFilters: ['All', 'fivePlus', 'threeToFour', 'zeroToTwo','zeroToThree','past'],
    columnDrag: false,
    searchHeaders: {},
    search: '',
    searchList: false,
    searchKey: 111,
    documentLoading: null,
    updateFilterValue: 222,
    filteredResults: [],
    fileTimeout: undefined,
    loadingFiles: false,
    showCompleted: false,
    fileRequest: undefined,
    files: [],
    shipmentCounts: null,
    document: {},
    documentTypes: [],
    documentTasks: [],
    documentUploads: [],
    actions: [],
    shipment: {},
    bookings: [],
    fileNumber: '',
    openKey: 5000,
    documentModal: false,
    filters: {},
    data:[
    ],
    headers: [
        {
            name: 'File No.',
            value: 'fileNumber',
            align: 'center',
            disableSort: false,
        },
        {
            name: 'Status',
            value: 'status',
            align: 'center',
            disableSort: false,
        },
        {
            name: 'Document',
            value: 'document',
            align: 'start',
            disableSort: false,
        },
        {
            name: 'Customer',
            value: 'customerName',
            align: 'start',
            disableSort: false,
        },
        {
            name: 'POL/POD',
            value: 'portOfLoadValue',
            align: 'center',
            disableSort: true,
            disableFilter: true,
        }
    ],
    listItems: [
        {name: 'Item 1'},
        {name: 'Item 2'},
        {name: 'Item 3'},
        {name: 'Item 4'},
        {name: 'Item 5'},
        {name: 'Item 6'},
        {name: 'Item 7'},
        {name: 'Item 8'},
        {name: 'Item 9'},
        {name: 'Item 10'},
    ],
    // w and h are the default number of columns and rows respectively that the widget takes up. 
    // Changing these values will not affect the default size as the size is stored in the widget itself. 
    // To change default size, either edit the widget in the Widget Dev Tools or change it in the widget.json file found in /server/src/static/widgets
    w: 1, 
    h: 1,
    // cellWidth and cellHeight are the actual widths and heights respectively of a cell in the grid, based on the users viewport size
    // These values are calculated and changed if the grid resizes, for instance if the right bar opens or if the navbar opens.
    cellWidth: 0, 
    cellHeight: 0,
}),
mounted() {
// DO NOT CHANGE THE CODE BELOW!!!
// This code is used to calculate the number of columns and rows the widget takes up when it is resized
// This is set up here because the sizes do not update dynamically from the parent component when the widget is resized
// We need these values to resize / reorder the contents of the widget when it is resized
// This also takes into account the resizing of the grid itself
this.$nextTick(() => {
    const path = this.widget
    const grid = document.getElementById('grid');
    const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
    const gridResizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
            const gridWidth = entry.contentRect.width;
            const gridHeight = entry.contentRect.height;
            this.cellWidth = gridWidth / 25;
            this.cellHeight = gridHeight / 14;
        }
    });
    gridResizeObserver.observe(grid, { box: 'content-box' });
    const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
            const widgetWidth = entry.contentRect.width;
            const widgetHeight = entry.contentRect.height;
            const cellWidth = this.cellWidth;
            const widgetColumns = Math.round(widgetWidth / cellWidth);
            this.w = widgetColumns;
            const cellHeight = this.cellHeight;
            const widgetRows = Math.round(widgetHeight / cellHeight);
            this.h = widgetRows;
        }
    });
    resizeObserver.observe(widget, { box: 'content-box' });
});
this.calculateFilteredResults();
    // DO NOT CHANGE THE CODE ABOVE!!!
},
created() {
    this.headers.forEach(header => {
    this.filters[header.value] = [];
    });
    this.getDocs()
},
watch: {
    columns() {
        this.w = this.columns;
    },
    rows() {
        this.h = this.rows;
    },
},
computed: {
    rejectedCount() {
        if(this.dataMap.length > 0) {
            return this.dataMap.filter(x => x.status == 'Rejected').length;
        } else {
            return 0;
        }
    },
    overdueCount() {
        if(this.dataMap.length > 0) {
            return this.dataMap.filter(x => x.kpiCategory == 'OVERDUE').length;
        } else {
            return 0;
        }
    },
    inDangerCount() {
        if(this.dataMap.length > 0) {
            return this.dataMap.filter(x => x.kpiCategory == 'DANGER').length;
        } else {
            return 0;
        }
    },
    withinKPICount() {
        if(this.dataMap.length > 0) {
            return this.dataMap.filter(x => x.kpiCategory == 'GOOD').length;
        } else {
            return 0;
        }
    },
    calculatedHeight() {
        let height = (this.h - 1) * this.cellHeight;
        return height * 0.95 + "px";
    },
    headersCols() {
        console.log(this.w)
        let headers = []
        if(this.headers.length > 0) {
            let min = 2
            let maxHeadersVisible = Math.floor(this.w / min)
            headers = this.headers.slice(0, maxHeadersVisible)
        }
        return headers
    },
    dataMap() {
        return this.filteredResults.filter(x => {   
            let daysFilter = true;
            if (this.past) {
                    daysFilter = x.daysFromToday < 0;
                }
                else if (this.fivePlus) {
                    daysFilter = x.daysFromToday >= 5;
                }
                else if (this.threeToFour) {
                    daysFilter = x.daysFromToday >= 3 && x.daysFromToday < 5;
                }
                else if (this.zeroToThree) {
                    daysFilter = x.daysFromToday >= 0 && x.daysFromToday < 4;
                }
                else if (this.zeroToTwo) {
                    daysFilter = x.daysFromToday >= 0 && x.daysFromToday < 3;
                }        
                let statusFilter = true;
                if (this.openFilesFilter) {
                    statusFilter = x.documentationStatus === 'OPEN';
                } else if (this.rejected) {
                    statusFilter = x.status === 'Rejected';
                } else if (this.overdue) {
                    statusFilter = x.kpiCategory === 'OVERDUE';
                } else if (this.inDanger) {
                    statusFilter = x.kpiCategory === 'DANGER';
                } else if (this.withinKPI) {
                    statusFilter = x.kpiCategory === 'GOOD';
                }         
            let searchFilter = true;
            if (this.search) {
                searchFilter = [ x.fileNumber, x.status, x.document, x.customerName, x.portOfLoadCity, x.portOfLoadValue, x.finalDestinationCity, x.finalDestinationValue
                ].some(field => field && field.toLowerCase().includes(this.search.toLowerCase()));
            }
            return searchFilter && daysFilter && statusFilter;
        }).sort((a, b) => {
            if (this.sortBy) {
                return this.sortDesc ? (a[this.sortBy] < b[this.sortBy] ? 1 : -1) : (a[this.sortBy] > b[this.sortBy] ? 1 : -1);
            }
            return 0;
        });
    },
    daysCalculated() {
        let result = {
            fivePlus: 0,
            threeToFour: 0,
            zeroToTwo: 0,
            zeroToThree:0,
            past: 0,
        }
        if(this.dataMap.length > 0) {
            const uniqueShipments = this.dataMap.reduce((unique, item) => {
                return unique.find(x => x.fileNumber === item.fileNumber) ? unique : [...unique, { fileNumber: item.fileNumber, daysFromToday: item.daysFromToday }];
            }, []);
            for(let i = 0; i < uniqueShipments.length; i++) {
                let days = uniqueShipments[i].daysFromToday;

                if (days < 0) {
                    result.past++;
                }
                else if (days >= 5) {
                    result.fivePlus++;
                }
                else if (days >= 3) {
                    result.threeToFour++; // This covers daysFromToday 3 and 4
                }
                else if (days >= 0) {
                    result.zeroToThree++; // This covers daysFromToday 0, 1, 2, and 3
                    if (days <= 2) {
                        result.zeroToTwo++; // This covers daysFromToday 0, 1, and 2
                    }
                }
                }
        }
        return result
    },
},
methods: {
    addToFilter(value, field) {
        if (this.filters[field].includes(value)) {
            this.filters[field] = this.filters[field].filter((x) => x != value);
        } else {
            this.filters[field].push(value);
        }
        this.updateFilterValue++;
        let keys = Object.keys(this.filters);
        let result = [];
        keys.forEach((x) => {
            result = [
            ...result,
            ...this.filters[x].map((y) => ({
                field: x,
                fieldName: this.headers.find((z) => z.value == x).text,
                value: y,
            })),
        ];
        });
        this.filteredValues = result;
        this.calculateFilteredResults();
    },
    async viewDocument(document) {
        if (document) {
            this.documentLoading = document.id
            let [doc, documentTasks, documentUploads, actions, shipment, bookings] = await Promise.allSettled([
                this.$API.getShipmentDocumentTypeDetail(document.id),
                this.$API.getShipmentDocumentTasks(document.id),
                this.$API.getShipmentDocuments(document.shipment.id),
                this.$API.getShipmentActions(document.shipment.id),
                this.$API.getSingleShipment(document.shipment.shipmentReference),
                this.$API.getShipmentBookings(document.shipment.shipmentFileId),
            ])
            this.document = doc.value
            console.log('document', this.document)
            if (this.document.variations) {
                this.documentTypes = [this.document]
            }
            this.documentTasks = documentTasks.value
            console.log('documentTasks', this.documentTasks)
            this.documentUploads = documentUploads.value
            console.log('documentUploads', this.documentUploads)
            this.actions = actions.value
            console.log('actions', this.actions)
            this.shipment = shipment.value
            console.log('shipment', this.shipment)
            this.bookings = bookings.value
            console.log('bookings', this.bookings)
            this.fileNumber = document.shipment.shipmentFile.fileNumber
            console.log('fileNumber', this.fileNumber)
            setTimeout(() => {
                this.openKey++
            }, 250)
            this.documentModal = true
            this.documentLoading = null
        }
    },
    async getDocs() {
        if(this.fileTimeout) {
            clearTimeout(this.fileTimeout);
        }
        if(this.loadingFiles && this.fileRequest) {
            this.fileRequest.abort()
        }
        this.fileTimeout = setTimeout(async () => {
            this.fileRequest = new AbortController();
            const signal = this.fileRequest.signal
            this.loadingFiles = true;
            let result = await this.$API.getAllDocumentsForOrg({
                userId: this.globalFilter.assignedUserId,
                documentationStatus: this.showCompleted ? ["COMPLETED", "OPEN"] : ["OPEN"],
                showCompleted: this.showCompleted,
            }, signal);
            this.data = result.documents;
            console.log(this.data)
            this.shipmentCounts = result.shipmentCounts;
            this.calculateFilteredResults();
            this.loadingFiles = false;
        }, 1000);
    },
    gridTemplateColumns() {
        return `repeat(auto-fit, minmax(${this.cellWidth -10}px, 1fr)`;
    },
    headerOptions(value) {
        let result = [
            ...new Set(
            this.data.map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
            ),
        ];
        let uniqueValues = [...new Set(result.map((x) => x.value))];
        if (this.searchHeaders[value]) {
            uniqueValues = uniqueValues.filter(
            (x) => x && x.toLowerCase().includes(this.searchHeaders[value].toLowerCase()));
        }
        result = uniqueValues.map((x) => result.find((y) => y.value === x));
        result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        return result;
    },
    changeSortBy(value) {
        if (this.sortBy == value) {
            if (this.sortDesc) {
                this.sortBy = null;
                this.sortDesc = false;
            } else {
                this.sortDesc = true;
            }
            } else {
            this.sortBy = value;
            this.sortDesc = false;
        }
    },
    switchFilter(filter) {
        if (['fivePlus', 'threeToFour', 'zeroToTwo','zeroToThree', 'past'].includes(filter)) {
            this.fivePlus = filter === 'fivePlus' ? !this.fivePlus : false;
            this.threeToFour = filter === 'threeToFour' ? !this.threeToFour : false;
            this.zeroToTwo = filter === 'zeroToTwo' ? !this.zeroToTwo : false;
            this.zeroToThree = filter === 'zeroToThree' ? !this.zeroToThree : false;
            this.past = filter === 'past' ? !this.past : false;
        } else if (['openFilesFilter', 'rejected', 'overdue', 'inDanger', 'withinKPI'].includes(filter)) {
            this.openFilesFilter = filter === 'openFilesFilter' ? !this.openFilesFilter : false;
            this.rejected = filter === 'rejected' ? !this.rejected : false;
            this.overdue = filter === 'overdue' ? !this.overdue : false;
            this.inDanger = filter === 'inDanger' ? !this.inDanger : false;
            this.withinKPI = filter === 'withinKPI' ? !this.withinKPI : false;
        }
    },
    calculateFilteredResults() {
        let filterKeys = Object.keys(this.filters);
        let filterResult = [];
        filterKeys.forEach((x) => {
            filterResult = [
            ...filterResult,
            ...this.filters[x].map((y) => ({
                field: x,
                fieldName: this.headers.find((z) => z.value == x).text,
                value: y,
            })),
        ];
        });
        this.filteredValues = filterResult;
        let result = [...this.data];
        let keys = Object.keys(this.filters);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (this.filters[key] && this.filters[key].length > 0) {
            result = result.filter((x) => this.filters[key].includes(x[key]));
            }
        }
        this.filteredResults = result;
    },
    async viewShipment(item) {
        this.$router.push({
            path: "/shipment/" + item.shipmentReference,
        });
    },
    removePtyLtd(name) {
        const regex = /\s*\([^)]*\)\s*|\s*\(pty\s*ltd\)\s*|\s*pty\s*ltd\s*|\s*limited\s*|\s*ltd\s*/gi;
        let final = name.replace(regex, '').trim();
        if (final.includes('()')) {
            final.replace('()', '');
        }
        return final;
    },
}
};
</script>  

<style scoped>
.headersSnap {
    display: grid;
    border-bottom: 1px solid var(--v-border-color);
    padding: 0 0px;
}
.whiteFaded {
    background-color: #ffffffad;
}
.smallerAvatar {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
}
.smallerAvatar:hover {
    cursor: pointer;
}
.file:hover {
    cursor: pointer;
}
.cellContain {
    contain: content;
}
.rejected {
    color: var(--v-danger-base) !important
}
.overdue {
    color: var(--v-redPop-lighten1) !important
}
.inDanger {
    color: #dfaa49 !important
}
.withinKPI {
    color: var(--v-tertiary-base) !important
}
.flagAvatar {
    height: 16px !important;
    min-width: 16px !important;
    width: 16px !important;
}
.searchChipWrap {
    width: 35%;
    display: grid;
    transition: width 0.2s ease-in-out;
    background-color: #55555585 !important;
}
.searchChipWrapSmall {
    width: 64px;
    display: grid;
    background-color: #55555585 !important;
    transition: width 0.2s ease-in-out;
}
.searchInput {
    width: 0px;
    transition: width 0.3s ease-in-out;
}
.searchInput ::v-deep .v-input__slot {
    padding: 0px 12px 0 8px !important;
}

.searchInput :is(.v-input__append-inner) {
    margin-top: 2px !important;
}
.tooly :is(.v-toolbar__content) {
    padding: 0px 8px !important;
}
</style>