<template>
    <div class="widgetWrap">
        <DynamicScaling v-if="widget == 'DynamicScaling'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <DynamicLists v-if="widget == 'DynamicLists'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex" :globalFilter="filterObj" />
        <DocsCounts v-if="widget == 'DocsCounts'" :columns="w" :rows="h" :widget="widget" class="widget" :filter="filterObj" :widgetIndex="widgetIndex" :activeWeeks="activeWeeks"/>
        <DocsWeeklySummary v-if="widget == 'DocsWeeklySummary'" :columns="w" :rows="h" :widget="widget" class="widget" :filter="filterObj" :key="filtersKey" :widgetIndex="widgetIndex" :activeWeeks="activeWeeks"/>
        <WeeklyVessels v-if="widget == 'WeeklyVessels'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <WindyWidget v-if="widget == 'WindyWidget'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <Widget1Test v-if="widget == 'Widget1Test'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMErrors v-if="widget == 'TUM/TUMErrors'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMDuration v-if="widget == 'TUM/TUMDuration'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMInvocations v-if="widget == 'TUM/TUMInvocations'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMThrottles v-if="widget == 'TUM/TUMThrottles'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMTradeUnits v-if="widget == 'TUM/TUMTradeUnits'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMWeekTradeUnits v-if="widget == 'TUM/TUMWeekTradeUnits'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMSeasonTradeUnits v-if="widget == 'TUM/TUMSeasonTradeUnits'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMTradeUnitEvents v-if="widget == 'TUM/TUMTradeUnitEvents'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMMovementEvents v-if="widget == 'TUM/TUMMovementEvents'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMTradeUnitPHC v-if="widget == 'TUM/TUMTradeUnitPHC'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <TUMDetailedPHC v-if="widget == 'TUM/TUMDetailedPHC'" :columns="w" :rows="h" :widget="widget" class="widget" :widgetIndex="widgetIndex"/>
        <span v-if="!widget || widget == ''">Widget Not Found</span>
    </div>
</template>

<script>
import DynamicScaling from '../widgets/DynamicScaling.vue'
import DocsCounts from '../widgets/DocsCounts.vue'
import DocsWeeklySummary from '../widgets/DocsWeeklySummary.vue'
import DynamicLists from '../widgets/DynamicLists.vue'
import WeeklyVessels from '../widgets/WeeklyVessels.vue'
import WindyWidget from '../widgets/WindyWidget.vue'
import Widget1Test from '../widgets/Widget1Test.vue'
import TUMErrors from '../widgets/TUM/TUMErrors.vue'
import TUMDuration from '../widgets/TUM/TUMDuration.vue'
import TUMInvocations from '../widgets/TUM/TUMInvocations.vue'
import TUMThrottles from '../widgets/TUM/TUMThrottles.vue'
import TUMTradeUnits from '../widgets/TUM/TUMTradeUnits.vue'
import TUMWeekTradeUnits from '../widgets/TUM/TUMWeekTradeUnits.vue'
import TUMSeasonTradeUnits from '../widgets/TUM/TUMSeasonTradeUnits.vue'
import TUMTradeUnitEvents from '../widgets/TUM/TUMTradeUnitEvents.vue'
import TUMMovementEvents from '../widgets/TUM/TUMMovementEvents.vue'
import TUMTradeUnitPHC from '../widgets/TUM/TUMTradeUnitPHC.vue'
import TUMDetailedPHC from '../widgets/TUM/TUMDetailedPHC.vue'
export default {
    components: {
        DynamicScaling,
        DocsCounts,
        DynamicLists,
        DocsWeeklySummary,
        WeeklyVessels,
        WindyWidget,
        Widget1Test,
        TUMErrors,
        TUMDuration,
        TUMInvocations,
        TUMThrottles,
        TUMTradeUnits,
        TUMWeekTradeUnits,
        TUMSeasonTradeUnits,
        TUMTradeUnitEvents,
        TUMMovementEvents,
        TUMTradeUnitPHC,
        TUMDetailedPHC,
    }, 
    props: [
        'widget',
        'w',
        'h',
        'filters',
        'widgetIndex',
        'activeWeeks'
    ],
    watch: {
        'filters': {
            immediate: true,
            handler(val) {
                this.filterObj = JSON.parse(JSON.stringify(val))
                this.filtersKey++,
                this.docsCountKey++
            }
        },
    },
    data: () => ({
        filterObj: {},
        filtersKey: 45,
        docsCountKey: 12,
    }),
}
</script>

<style scoped>
.widget {
    height: inherit;
    width: inherit;
    padding: 2px;
}

.widgetWrap {
    width: inherit; 
    height: inherit; 
    overflow: hidden;
}
</style>