<template>
    <div class="gridWrap">
        <div class="grid" :style="{ gridTemplateColumns: gridTemplateColumns, gridTemplateRows: gridTemplateRows }">
            <div v-for="card in visibleCards" :key="card.id" class="card" >
                <span class="mb-2">{{ card.name }}</span>
                <span style="color: var(--v-redPop-base) !important;">{{ getRandomNumber() }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
props: {
    widget: {
        type: String,
        required: true,
    },
    columns: {
        type: Number,
        required: true,
    },
    rows: {
        type: Number,
        required: true,
    },
    widgetIndex: {
        type: Number,
        required: true,
    },
    
},
data:() => ({ 
    cards: [
        { id: 1, name: 'Card 1' },
        { id: 2, name: 'Card 2' },
        { id: 3, name: 'Card 3' },
        { id: 4, name: 'Card 4' },
    ],
    w: 4,
    h: 4,
    cellWidth: 0,
    cellHeight: 0,
    cellMinWidth: 2,
    cellMaxWidth: 4,
    cellMinHeight: 2,
    cellMaxHeight: 4,
}),
mounted() {
    // DO NOT CHANGE THE CODE BELOW!!!
    // This code is used to calculate the number of columns and rows the widget takes up when it is resized
    // This is set up here because the sizes do not update dynamically from the parent component when the widget is resized
    // We need these values to resize / reorder the contents of the widget when it is resized
    // This also takes into account the resizing of the grid itself
    this.$nextTick(() => {
        const path = this.widget
        const grid = document.getElementById('grid');
        const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
        let gridWidth = grid.offsetWidth;
        let gridHeight = grid.offsetHeight;
        let cellWidth = gridWidth / 25;
        this.cellWidth = cellWidth;
        let cellHeight = gridHeight / 14;
        this.cellHeight = cellHeight;
        const gridResizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                gridWidth = entry.contentRect.width;
                gridHeight = entry.contentRect.height;
                cellWidth = gridWidth / 25;
                this.cellWidth = cellWidth;
                cellHeight = gridHeight / 14;
                this.cellHeight = cellHeight;
            }
        });
        gridResizeObserver.observe(grid, { box: 'content-box' });
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const widgetWidth = entry.contentRect.width;
                const widgetHeight = entry.contentRect.height;
                const widgetColumns = Math.round(widgetWidth / cellWidth);
                this.w = widgetColumns;
                const widgetRows = Math.round(widgetHeight / cellHeight);
                this.h = widgetRows;
            }
        });
        resizeObserver.observe(widget, { box: 'content-box' });
    });
    // DO NOT CHANGE THE CODE ABOVE!!!
},
watch: {
    columns() {
        this.w = this.columns;
    },
    rows() {
        this.h = this.rows;
    },
},
computed: {

    visibleCards() {
        const maxCardsWidth = Math.floor(this.w / this.cellMinWidth);
        const maxCardsHeight = Math.floor(this.h / this.cellMinHeight);
        let max =  maxCardsWidth * maxCardsHeight;
        return this.cards.slice(0, max);
    },
    gridTemplateColumns() {
        return `repeat(auto-fit, minmax(${this.widthMinMax(this.cellMinWidth, this.cellMaxWidth).low}px, 1fr)`;
    },
    gridTemplateRows() {
        return `repeat(auto-fit, minmax(${this.heightMinMax(this.cellMinHeight, this.cellMaxHeight).low}px, 1fr)`;
    },

},
methods: {
    getRandomNumber() {
        return Math.floor(Math.random() * 1000) + 1;
    },
    widthMinMax(min, max) {
        let low = (min * this.cellWidth) - 2;
        let high = (max * this.cellWidth) - 2;
        return { low, high };
    },
    heightMinMax(min, max) {
        let low = (min * this.cellHeight) - 2;
        let high = (max * this.cellHeight) - 2;
        return { low, high };
    }
}
};
</script>  
<style scoped>
.grid {
    display: grid;
    contain: content;
    width: 100%;
    height: 100%;
    gap: 0.4rem;
}
.gridWrap {
    height: inherit; 
    width: inherit; 
    overflow-y: scroll; 
    scroll-snap-type: y mandatory;
}

.gridWrap > * {
    scroll-snap-align: start;
}

.card {
    background-color: var(--v-toolbar-lighten1) !important;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
