<template>
    <div>
        <v-card
          flat
          :key="weatherKey"
          style="overflow-y: scroll"
          height="100%"
        >
          <div v-if="selectedPort.lat && selectedPort.lng">
            <iframe
              width="100%"
              height="450"
              :src="`https://embed.windy.com/embed2.html?lat=${selectedPort.lat}&lon=${selectedPort.lng}&detailLat=${selectedPort.lat}&detailLon=${selectedPort.lng}&width=650&height=450&zoom=11&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1`"
              frameborder="0"
            ></iframe>
          </div>
          <div v-else>
            <iframe
              width="100%"
              :height="h * cellHeight"
              :src="`https://embed.windy.com/embed2.html?lat=${tempSelectedPort.lat}&lon=${tempSelectedPort.lng}&detailLat=${tempSelectedPort.lat}&detailLon=${tempSelectedPort.lng}&width=650&height=450&zoom=11&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1`"
              frameborder="0"
            ></iframe>
          </div>
          <div class="floatingSelect">
            <v-menu bottom offset-y :close-on-content-click="false" :key="portKey">
              <template v-slot:activator="{on, attrs}">
                <v-chip v-on="on" v-bind="attrs">
                  <v-avatar size="32" class="mr-2">
                    <v-img
                      v-if="selectedPort.countryCode"
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${selectedPort.countryCode.toLowerCase()}.svg`"
                    >
                    </v-img>
                    <v-icon v-else>search</v-icon>
                  </v-avatar>
                  <span v-if="selectedPort.name && selectedPort.locode">{{ selectedPort.name }} ({{ selectedPort.locode }})</span>
                  <span v-else>Select Port</span>
                </v-chip>
              </template>
              <v-card>
                <v-card-text>
                    <v-autocomplete
                    auto-focus
                    hide-details
                    outlined
                    return-object
                    dense
                    hide-no-data
                    clearable
                    style="max-width: 300px"
                    prepend-inner-icon="search"
                    rounded
                    label="Search Port"
                    v-model="selectedPort"
                    :loading="loadingPorts"
                    :search-input.sync="portSearch"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="ports"
                    :filter="filterObject"
                    item-text="name"
                    item-value="id"
                  >
                    <template v-slot:selection="data">
                      <v-avatar size="32" class="mr-2" v-if="data.item">
                        <v-img
                          v-if="data.item.countryCode"
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`"
                        >
                        </v-img>
                      </v-avatar>
                      <span v-if="data.item">
                        {{ data.item.name }}
                      </span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <v-img
                          contain
                          v-if="data.item.countryCode"
                          :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`"
                        >
                        </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.locode"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-subheader style="font-size: 12px; height: 36px;">
                  Favourite Ports
                </v-subheader>
                <v-progress-linear
                  v-if="loadingFavourites"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
                <v-list
                  v-if="!loadingFavourites"
                  id="fav-port"
                  class="scroll py-0"
                  dense
                >
                  <v-list-item
                  dense
                    v-for="fav in favourites"
                    :key="fav.id"
                    :style="{
                      border: '0.5px solid grey',
                      'border-left':
                        selectedPort && selectedPort.id == fav.unLocode.id
                          ? '3px solid var(--v-primary-base)'
                          : '0.5px solid grey',
                    }"
                    @click="selectedPort = fav.unLocode"
                  >
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          v-if="fav.unLocode.countryCode"
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${fav.unLocode.countryCode.toLowerCase()}.svg`"
                        >
                        </v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ fav.unLocode.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ fav.unLocode.locode }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="favourites.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No favourites</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              
                <v-subheader style="font-size: 12px; height: 36px;">
                  Suggested Ports
                </v-subheader>
                <v-progress-linear
                  v-if="loadingSuggestions"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
                <v-list
                  dense
                  v-if="!loadingSuggestions"
                  id="suggested-port"
                  class="scroll py-0"
                  max-height="48vh"
                >
                  <v-list-item
                    dense
                    v-for="suggested in suggestedPorts"
                    :key="suggested.id"
                    :style="{
                      border: '0.5px solid grey',
                      'border-left':
                        selectedPort && selectedPort.id == suggested.id
                          ? '3px solid var(--v-primary-base)'
                          : '0.5px solid grey',
                    }"
                    @click="selectedPort = suggested"
                  >
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          v-if="suggested.countryCode"
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${suggested.countryCode.toLowerCase()}.svg`"
                        >
                        </v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ suggested.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ suggested.locode }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="suggestedPorts.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No suggestions</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        widget: {
            type: String,
            required: true,
        },
        columns: {
            type: Number,
            required: true,
        },
        rows: {
            type: Number,
            required: true,
        },
        widgetIndex: {
          type: Number,
          required: true,
        },
    },
    data: () => ({
        weatherKey: 20,
        tempSelectedPort: {
            lat:-33.92,
            lng:18.42,
        },
        portSearch: null,
        portKey: 121,
        searchTimer: null,
        loadingPorts: false,
        ports: [],
        selectedPort: {},
        loadingFavourites: false,
        favourites: [],
        loadingSuggestions: false,
        suggestedPorts: [],
        // w and h are the default number of columns and rows respectively that the widget takes up. 
        // Changing these values will not affect the default size as the size is stored in the widget itself. 
        // To change default size, either edit the widget in the Widget Dev Tools or change it in the widget.json file found in /server/src/static/widgets
        w: 1, 
        h: 1,

        // cellWidth and cellHeight are the actual widths and heights respectively of a cell in the grid, based on the users viewport size
        // These values are calculated and changed if the grid resizes, for instance if the right bar opens or if the navbar opens.
        cellWidth: 0, 
        cellHeight: 0,
    }),
    mounted() {
// DO NOT CHANGE THE CODE BELOW!!!
// This code is used to calculate the number of columns and rows the widget takes up when it is resized
// This is set up here because the sizes do not update dynamically from the parent component when the widget is resized
// We need these values to resize / reorder the contents of the widget when it is resized
// This also takes into account the resizing of the grid itself
        this.$nextTick(() => {
            const path = this.widget
            const grid = document.getElementById('grid');
            const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
            let gridWidth = grid.offsetWidth;
            let gridHeight = grid.offsetHeight;
            let cellWidth = gridWidth / 25;
            this.cellWidth = cellWidth;
            let cellHeight = gridHeight / 14;
            this.cellHeight = cellHeight;
            const gridResizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    gridWidth = entry.contentRect.width;
                    gridHeight = entry.contentRect.height;
                    cellWidth = gridWidth / 25;
                    this.cellWidth = cellWidth;
                    cellHeight = gridHeight / 14;
                    this.cellHeight = cellHeight;
                }
            });
            gridResizeObserver.observe(grid, { box: 'content-box' });
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const widgetWidth = entry.contentRect.width;
                    const widgetHeight = entry.contentRect.height;
                    const widgetColumns = Math.round(widgetWidth / cellWidth);
                    this.w = widgetColumns;
                    const widgetRows = Math.round(widgetHeight / cellHeight);
                    this.h = widgetRows;
                    console.log(`Widget: ${widgetColumns} cols x ${widgetRows} rows`);
                }
            });
            resizeObserver.observe(widget, { box: 'content-box' });
        });
        // DO NOT CHANGE THE CODE ABOVE!!!
    },
    watch: {
        columns() {
            this.w = this.columns;
        },
        rows() {
            this.h = this.rows;
        },
        async portSearch(val) {
          if (val) {
            await this.getMonitoredPorts();
          }
        },
        selectedPort(val) {
          if (val) {
            this.portKey++
            this.weatherKey++
          }
        },
    },
    created() {
        this.getFavouritePorts()
        this.getSuggestedPorts()
    },    
    computed: {

    },
    methods: {
        async getMonitoredPorts() {
          if (this.searchTimer) {
            clearTimeout(this.searchTimer);
          }
          this.loadingPorts = true;
          this.searchTimer = setTimeout(async () => {
            this.ports = await this.$API.getPorts({
              params: {
                search: this.portSearch,
              },
            });
            this.loadingPorts = false;
          }, 500);
        },
        async getFavouritePorts() {
          this.loadingFavourites = true;
          this.favourites = await this.$API.getFavouritePorts();
          if (
            this.favourites.length > 0 &&
            (!this.selectedPort || !this.selectedPort.id)
          ) {
            this.selectedPort = this.favourites[0].unLocode;
          }
          this.loadingFavourites = false;
        },
        async getSuggestedPorts() {
          this.loadingSuggestions = true;
          this.suggestedPorts = await this.$API.getSuggestedPorts();
          this.loadingSuggestions = false;
        },
        filterObject(item, queryText, itemText) {
          return (
            item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
              -1 ||
            item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
              -1
          );
        },
    }
};
</script>  

<style scoped>
.floatingSelect {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 9999;
}
</style>