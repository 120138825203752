<template>
    <div>
        <v-card flat style="border-radius: 8px;background: var(--v-toolbar-lighten1) !important; height: 100%;">
      <v-card-text class="grid px-0" :style="{ gridTemplateColumns: gridTemplateColumns()}">
        <div style="grid-column: span 4" v-if="w >= 13">
          <v-card flat style="background: var(--v-toolbar-lighten1) !important">
            <v-list>
                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-content>
                    <!-- <v-list-item-title>Unprocessed Files</v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-icon class="mr-3 mb-2 mt-3" >
                    <v-icon>pending_actions</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Unprocessed Files
                    </v-list-item-title>
                    <v-list-item-subtitle style="height: 20px !important">
                      Totals: <v-chip @click="openUnprocessed(false)" style="height: 20px !important;" small class="pa-1 mx-1">{{ data.totals?.unprocessedFiles  ?? 0 }}</v-chip>
                      </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-icon class="mr-3 mb-2 mt-3">
                    <v-icon>account_balance</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Not Ready to Invoice
                    </v-list-item-title>
                    <v-list-item-subtitle style="height: 20px !important">
                      Totals: <v-chip @click="openViewNotReady(false)" style="height: 20px !important;" small class="pa-1 mx-1">{{ data.totals?.notReadyToInvoice  ?? 0 }}</v-chip>
                      </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-icon class="mr-3 mb-2 mt-3">
                    <v-icon>checklist</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Documentation</v-list-item-title>
                    <v-list-item-subtitle style="height: 20px !important">
                      Totals:
                      <v-chip @click="openDocumentationDialog(false, 'OVERDUE', data.totals?.OVERDUE ?? 0)" class="pa-1 mx-1" style="height: 20px !important;" color="error" small>{{ data.totals?.OVERDUE  ?? 0 }}</v-chip>
                      <v-chip @click="openDocumentationDialog(false, 'DANGER', data.totals?.DANGER ?? 0)" class="pa-1 mx-1" style="height: 20px !important;" color="warning" small>{{ data.totals?.DANGER  ?? 0 }}</v-chip>
                      <v-chip @click="openDocumentationDialog(false, 'GOOD', data.totals?.GOOD ?? 0)" class="pa-1 mx-1" style="height: 20px !important;" color="success" small>{{ data.totals?.GOOD  ?? 0 }}</v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-icon class="mr-3 mb-2 mt-3">
                    <v-icon>assignment</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Open Files
                    </v-list-item-title>
                    <v-list-item-subtitle style="height: 20px !important">
                      Totals: <v-chip @click="viewOpenFilesDialog(false)"  style="height: 20px !important;" small class="pa-1 mx-1">{{ data.totals?.openFiles ?? 0 }}</v-chip>
                      </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
          </v-card>
        </div>
        <div :style="w >= 13 ? {'grid-column': `span ${w - 4}`} : {'grid-column' : `span ${w}`}" class="d-flex" >
          <div class="d-flex justify-center align-center">
            <v-btn
              icon
              v-if="!loading && activeWeeks.length > 0"
              @click="prevPage()"
              color="primary"
              :disabled="page == 0"
              >
              <v-icon>arrow_back</v-icon>
            </v-btn>
          </div>
          <div :style="{gridTemplateColumns: gridTemplateColumns()} " class="cardGrid" v-if="availableData.length > 0">
            <div v-for="(week, index) in sliceWeeks.data" :key="index" style="grid-column: span 2;">
              <v-card  style="background: var(--v-component-base) !important; border-radius: 20px; height: 100%;" v-if="!loading">
                <v-list>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          {{ week.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          <v-chip
                            @click="openUnprocessed(week, week.unprocessedFiles)"
                            color="secondary"
                            v-if="week.unprocessedFiles > 0"
                            >{{ week.unprocessedFiles }}</v-chip
                          ><span v-else>-</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          <v-chip
                            @click="openViewNotReady(week)"
                            color="secondary"
                            v-if="week.notReadyToInvoice > 0"
                            >{{ week.notReadyToInvoice }}</v-chip
                          > <span v-else>-</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          <v-chip
                            @click="openDocumentationDialog(week, 'OVERDUE', week.OVERDUE)"
                            v-if="week.OVERDUE"
                            color="error"
                            class="mx-1"
                            >{{ week.OVERDUE }}</v-chip
                          >
                          <v-chip
                            @click="openDocumentationDialog(week, 'DANGER', week.DANGER)"
                            v-if="week.DANGER"
                            color="warning"
                            class="mx-1"
                            >{{ week.DANGER }}</v-chip
                          >
                          <v-chip
                            @click="openDocumentationDialog(week, 'GOOD', week.GOOD)"
                            v-if="week.GOOD"
                            color="success"
                            class="mx-1"
                            >{{ week.GOOD }}</v-chip
                          >
                          <span v-if="!week.OVERDUE && !week.DANGER && !week.GOOD">-</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          <v-chip @click="viewOpenFilesDialog(week, week.openFiles)" color="secondary" v-if="week.openFiles > 0">{{
                            week.openFiles
                          }}</v-chip><span v-else>-</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
              </v-card>
              <v-card
                  elevation="10"
                  v-else
                  style="
                    background: var(--v-component-lighten1) !important;
                    border-radius: 20px;
                    height: 280px
                  "
                >
                  <v-skeleton-loader tile
                    type="list-item, list-item, list-item, list-item,list-item,list-item"
                  ></v-skeleton-loader>
                </v-card>
            </div>
          </div>
          <div :style="{gridTemplateColumns: gridTemplateColumns()} " class="cardGrid" v-if="loading && availableData.length == 0" >
              <div v-for="i in 6" :key="i"  style="grid-column: span 2;">
                <v-card
                  elevation="10"
                  style="
                    background: var(--v-component-lighten1) !important;
                    border-radius: 20px;
                    height: 280px
                  "
                >
                  <v-skeleton-loader tile
                    type="list-item, list-item, list-item, list-item,list-item,list-item"
                  ></v-skeleton-loader>
                </v-card>
              </div>
            </div>
          <div class="d-flex justify-center align-center">
            <v-btn
              icon
              v-if="!loading && activeWeeks.length > 0"
              @click="nextPage()"
              color="primary"
              :disabled="page == Math.ceil(activeWeeks.length / pageLimit) - 1"
            >
              <v-icon>arrow_forward</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="openDocsDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        style="overflow: hidden; contain: content;"
    >
    <v-card style="height: 100vh;  contain: content">
      <DashboardDocsModal
      :opened="openDocsDialog"
      :week="chosenWeek"
      :kpiCategory="chosenKpiCategory"
      :filter="filterForDialog"
      @close="closeDocsDialog()"
      />
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="viewUnprocessed"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
    <v-card>
        <ReadyFiles :week="chosenWeek" :opened="viewUnprocessed" :filter="filterForDialog" :isDialog="true" @close="closeViewUnprocessed"/>
    </v-card>
    </v-dialog>
    <v-dialog
        v-model="viewNotReady"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card>
        <NotReadyToInvoice :week="chosenWeek" :opened="viewNotReady" :filter="filterForDialog" :isDialog="true" @close="closeViewNotReady"/>
    </v-card>
    </v-dialog>
    <v-dialog
        v-model="viewOpenFiles"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card>
        <OpenFiles :opened="viewOpenFiles" :filter="filterForDialog" :week="chosenWeek" :isDialog="true" @close="closeOpenFiles"/>
    </v-card>
  </v-dialog>
    <v-dialog v-model="errorDialog" width="40vw">
      <v-card height="auto">
        <v-toolbar flat dense class="d-flex justify-center align-center">
          <v-toolbar-title>Request too large.</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="d-flex justify-center align-center text-center pb-0">
          This request is too large to process and will likely timeout. Please apply a filter to reduce the number of results to under 500. 
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn @click="errorDialog = false" color="redPop">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import DashboardDocsModal from "../../Shipments/DashboardDocsModal.vue";
import ReadyFiles from "../../Shipments/ReadyFiles.vue";
import NotReadyToInvoice from "../../Shipments/NotReadyToInvoice.vue";
import OpenFiles from "../../Shipments/OpenFiles.vue";
export default {
  components: {
    DashboardDocsModal,
    ReadyFiles,
    NotReadyToInvoice,
    OpenFiles
  },
  inject: ['filterObj'],
  props: {
    widget: {
        type: String,
        required: true,
    },
    columns: {
        type: Number,
        required: true,
    },
    rows: {
        type: Number,
        required: true,
    },
    filter: {
        type: Object,
        required: true,
    },
    widgetIndex: {
        type: Number,
        required: true,
    },
},
data: () => ({
    w: 1, 
    h: 1,
    cellWidth: 0, 
    cellHeight: 0,
    cellMinWidth: 4,
    cellMaxWidth: 25,
    cellMinHeight: 5,
    cellMaxHeight: 5,
    activeWeeks: [],
    data: [],
    loadingWeeks: false,
    weekQuery: undefined,
    weekTimeout: undefined,
    weekKey: 300,
    dataQuery: undefined,
    dataTimeout: undefined,
    errorDialog: false,
    viewNotReady: false,
    viewOpenFiles: false,
    viewUnprocessed: false,
    loading: false,
    snapPoint: 13,
    page: 0,
    pageLimit: 6,
    notReadyKey: 22,
    chosenWeek: null,
    chosenKpiCategory:  null,
    openDocsDialog: false,
    filterForDialog: {},
}),
mounted() {
  this.getActiveWeeks();
    this.$nextTick(() => {
        const path = this.widget
        const grid = document.getElementById('grid');
        const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
        let gridWidth = grid.offsetWidth;
        let gridHeight = grid.offsetHeight;
        let cellWidth = gridWidth / 25;
        this.cellWidth = cellWidth;
        let cellHeight = gridHeight / 14;
        this.cellHeight = cellHeight;
        const gridResizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                gridWidth = entry.contentRect.width;
                gridHeight = entry.contentRect.height;
                cellWidth = gridWidth / 25;
                this.cellWidth = cellWidth;
                cellHeight = gridHeight / 14;
                this.cellHeight = cellHeight;
            }
        });
        gridResizeObserver.observe(grid, { box: 'content-box' });
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const widgetWidth = entry.contentRect.width;
                const widgetHeight = entry.contentRect.height;
                const widgetColumns = Math.round(widgetWidth / cellWidth);
                this.w = widgetColumns;
                const widgetRows = Math.round(widgetHeight / cellHeight);
                this.h = widgetRows;
            }
        });
        resizeObserver.observe(widget, { box: 'content-box' });
    });
},
created() {
  this.loading = true;
    this.getActiveWeeks();
},
watch: {
    filterKey() {
      this.getActiveWeeks();
    },
    weekKey() {
      this.getFilteredData();
    },
    columns() {
        this.w = this.columns;
    },
    rows() {
        this.h = this.rows;
    },
    filterObj: {
      immediate: true,
      handler(val) {
        console.log('INJECTED', val)
      },
    }
},
computed: {
  availableData() {
    if(this.data && this.data.weekResult) {
      return this.data.weekResult.filter(
        (x) =>
        x.notReadyToInvoice > 0 || x.unprocessedFiles > 0 || x.openFiles > 0
      );
    } else {
      return []
    }
    },
    availableWeeks() {
      let data = [...this.activeWeeks];
      let sliceResult = data.slice(
        this.page * this.pageLimit,
        (this.page * this.pageLimit) + this.pageLimit
      );
      return sliceResult
    },
    sliceWeeks() {
        let data = [...this.availableData]
        let change = this.w >= this.snapPoint ? 6 : 2;
        let maxCardsWidth = Math.ceil((this.w - change) / 2);
        let min = this.page * maxCardsWidth;
        let max = this.page * maxCardsWidth + maxCardsWidth;
        return {data: data.slice(min, max), maxCardsWidth: maxCardsWidth};
    },

},
methods: {
    gridTemplateColumns() {
        return `repeat(auto-fit, minmax(${this.cellWidth -10}px, 1fr)`;
    },
    closeViewNotReady() {
      this.chosenWeek = null;
      this.filterForDialog = {}
      this.viewNotReady = false;
    },
    openViewNotReady(week) {
      this.chosenWeek = week ? week.text : null;
      this.filterForDialog = {...this.filter}
      this.viewNotReady = true;
    },
    openUnprocessed(week) {
      this.chosenWeek = week ? week.text : null;
      this.filterForDialog = {...this.filter}
      this.viewUnprocessed = true;
    },
    closeViewUnprocessed() {
      this.chosenWeek = null;
      this.filterForDialog = {}
      this.viewUnprocessed = false;
    },
    viewOpenFilesDialog(week) {
      this.chosenWeek = week ? week.text : null;
      this.filterForDialog = {...this.filter}
      this.viewOpenFiles = true;
    },
    closeOpenFiles(){
      this.chosenWeek = null;
      this.filterForDialog = {}
      this.viewOpenFiles = false;
    },

    openDocumentationDialog(week, kpiCategory, total) {
      if(total > 500 ) {
        if(!this.filter.assignedUserId && !this.filter.customerId && !this.filter.vesselVoyage && (!this.filter.teams || this.filter.teams.length == 0)) {
          this.errorDialog = true;
          return
        }
      }
      this.chosenWeek = week ? week : null;
      this.chosenKpiCategory = kpiCategory;
      this.filterForDialog = {...this.filter}
      this.openDocsDialog = true;
    },
    closeDocsDialog() {
      this.chosenWeek = null;
      this.chosenKpiCategory = null;
      this.filterForDialog = {}
      this.openDocsDialog = false;
    },
    async getFilteredData() {
      if (this.loading) {
        clearTimeout(this.dataTimeout);
      }
      if (this.loading && this.dataQuery) {
        this.dataQuery.abort();
      }
      this.loading = true;
      this.dataTimeout = setTimeout(async () => {
        this.dataQuery = new AbortController();
        let filterFinal = null
        if(this.filter && this.filter.vesselVoyage && this.filter.vesselVoyage?.voyage) {
            filterFinal = {
                ...this.filter,
                vesselVoyage: {
                    voyage: this.filter.vesselVoyage.voyage,
                    id: this.filter.vesselVoyage.id,
                }
            }
        } else {
            filterFinal = {...this.filter}
        }
        const signal = this.dataQuery.signal;
        let filter = {
          ...filterFinal,
          weeks: this.availableWeeks.map((x) => x.text),
        };
        if(filter.weeks.length > 6) {
          filter.weeks = filter.weeks.slice(0, 6)
        }
        this.data = await this.$API.getDocumentDashboardData({
          params: filter,
          signal,
        });
        let weeklyTotal = this.data.weekResult;
        this.totalsAll = this.data.totals;
        let unprocessedFileTotal = weeklyTotal.reduce(
          (acc, week) => acc + week.unprocessedFiles,
          0
        );
        let notReadyToInvoiceTotal = weeklyTotal.reduce(
          (acc, week) => acc + week.notReadyToInvoice,
          0
        );
        let documentationTotal = weeklyTotal.reduce(
          (acc, week) => acc + week.documentTotal,
          0
        );
        let openFilesTotal = weeklyTotal.reduce(
          (acc, week) => acc + week.openFiles,
          0
        );
        this.$emit("updateTotals", {
          unprocessedFileTotal,
          notReadyToInvoiceTotal,
          documentationTotal: documentationTotal,
          openFilesTotal,
        });
        this.loading = false;
      }, 500);
    },
    async getActiveWeeks() {
      //   this.loadingActiveWeeks = true;
      this.loading = true
      if (this.loadingWeeks) {
        clearTimeout(this.weekTimeout);
      }
      if (this.loadingWeeks && this.weekQuery) {
        this.weekQuery.abort();
      }
      this.loadingWeeks = true;
      let filterFinal = null
      // let {vesselVoyage, ...filterVessel} = this.filter
      if(this.filter && this.filter.vesselVoyage && this.filter.vesselVoyage?.voyage) {
          filterFinal = {
              ...this.filter,
              vesselVoyage: {
                  voyage: this.filter.vesselVoyage.voyage,
                  id: this.filter.vesselVoyage.id,
              }
          }
      } else {
          filterFinal = {...this.filter}
      }
      this.weekTimeout = setTimeout(async () => {
        this.weekQuery = new AbortController();
        const signal = this.weekQuery.signal;
      this.activeWeeks = await this.$API.getActiveWeeks({
        params: filterFinal,
        signal
      });
      this.loadingWeeks = false;
      this.weekKey ++
      }, 500);
      //   this.loadingActiveWeeks = false;
    },
    nextPage() {
      this.page++;
      this.getFilteredData();
    },
    prevPage() {
      this.page--;
      this.getFilteredData();
    },
}
};
</script>  

<style scoped>
.grid {
    display: grid;
    contain: content;
    width: 100%;
    height: 100%;
    gap: 0.4rem;
}
.cardsContainer {
  display: grid;
  contain: content;
  width: 100%;
  height: 100%;
  gap: 0rem;
}
.cardGrid {
  display: grid;
  contain: content;
  width: 94%;
  height: 100%;
  gap: 0.4rem;
  align-content: center;
}
.sideBar {
  width: 3%;
  height: 100%;
}
</style>