<template>
  <div>
    <transition name="slide-marker">
      <v-card style="position: absolute;
    height: 5vh;
    padding: 0.5vh 0 0.5vh 1vw;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: grey !important;
    color: grey !important;
    width: 3vw;
    z-index: 2;
    top: 1vh;
    left: 0vw;" v-if="!viewTaskInfo">
        <v-btn icon plain @click="viewTaskInfo = true">
          <v-icon>info</v-icon>
        </v-btn>
      </v-card>
    </transition>
    <v-card flat class="my-0 py-0">
      <!-- <v-toolbar flat color="transparent">
          <v-toolbar-title class="ml-15">
            Manual Inspection
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')">X</v-btn>
        </v-toolbar> -->
      <v-card-text class="my-0 py-0">
        <v-row class="no-gutters no-wrap">
          <transition name="slide-nav">
            <v-col cols="12" sm="2" style="height: 90vh" v-if="viewTaskInfo">
              <v-card flat outlined style="height: 100%">
                <v-card-title>Task: #{{ task.id }} <v-spacer></v-spacer>
                  <v-btn text @click="viewTaskInfo = false"><v-icon>chevron_left</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-list dense style="max-height: 80vh; overflow-y: auto">
                    <v-list-item v-if="task.submittedForInspectionBy">
                      <v-list-item-action>
                        <v-icon>person</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="task.submittedForInspectionBy">
                          {{ task.submittedForInspectionBy.firstName }} {{ task.submittedForInspectionBy.surname }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Requested inspection
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="task.startedBy">
                      <v-list-item-action>
                        <v-icon>local_police</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ task.startedBy.firstName }} {{ task.startedBy.surname }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Inspection started
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="inspectionResult.inspectedBy">
                      <v-list-item-action>
                        <v-icon>local_police</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ inspectionResult.inspectedBy.firstName }} {{ inspectionResult.inspectedBy.surname }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Inspector
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="inspectionResult.end">
                      <v-list-item-action>
                        <v-icon>schedule</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ formatDate(inspectionResult.end) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Inspection Date
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="inspectionResult.comments"></v-divider>

                    <v-subheader style="font-size: 16px" v-if="inspectionResult.comments">Comments</v-subheader>
                    <v-list-item v-if="inspectionResult.comments">
                      <v-list-item-content>
                        {{ inspectionResult.comments }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="inspectionResult.comments"></v-divider>

                    <v-subheader style="font-size: 16px" v-if="inspectionHistory && inspectionHistory.length > 0">
                      Inspection History
                    </v-subheader>
                    <v-divider v-if="inspectionHistory && inspectionHistory.length > 0"></v-divider>

                    <v-list-item v-for="history in inspectionHistory" :key="history.id">
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mr-2" v-on="on" :color="getTaskStatus(history).color">{{
                              getTaskStatus(history).icon
                            }}</v-icon>
                          </template>
                          <span>{{ getTaskStatus(history).name }}</span> </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-content>

                        <v-list-item-title class="text-wrap">
                          {{ formatDate(history.start) }} - {{ formatDate(history.end) }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="history.startedBy">
                          {{ history.startedBy.firstName }} {{ history.startedBy.surname }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="history.comments" style="font-size: 12px" class="text-wrap">
                          <v-icon small class="mr-1">description</v-icon> {{ history.comments }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                    </v-list-item>
                    <v-divider></v-divider>

                    <v-subheader style="font-size: 16px"
                      v-if="inspectionResult && inspectionResult.taskInspectionResultFields && inspectionResult.taskInspectionResultFields.length > 0">
                      Inspection Log
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-for="log in inspectionResult.taskInspectionResultFields" :key="log.id">
                      <v-list-item-action>
                        <v-icon :color="fieldStatusColor(log.field)">{{ fieldStatusIcon(log.field) }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ fieldFriendlyName(log.field) }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="log.inspectedBy">
                          {{ log.inspectedBy.firstName }} {{ log.inspectedBy.surname }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ formatDate(log.time) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                    </v-list-item>

                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </transition>
          <v-expand-transition>
            <v-col cols="12" :sm="viewTaskInfo ? 10 : 12" class="grow my-0 py-0">
              <v-card flat v-if="addendum && !loading" class="my-0 py-0">
                <v-card-text class="mb-0 pb-0">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field readonly disabled solo dense v-model="addendum.agentName" persistent-hint
                        hint="Name of Forwarding Agent"></v-text-field>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="addendum.portOfLoadCode" persistent-hint
                            hint="Port of Load">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :color="fieldStatusColor('portOfLoadCode')"
                                :loading="loadingField == 'portOfLoadCode'"
                                @click="changeStatus('portOfLoadCode')"><v-icon>{{
                                  fieldStatusIcon('portOfLoadCode')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.portOfDischargeCode"
                            persistent-hint hint="Port of Entry">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :color="fieldStatusColor('portOfDischargeCode')"
                                :loading="loadingField == 'portOfDischargeCode'"
                                @click="changeStatus('portOfDischargeCode')"><v-icon>{{
                                  fieldStatusIcon('portOfDischargeCode')
                                }}</v-icon></v-btn>
                            </template></v-text-field>

                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="addendum.targetCountry" persistent-hint
                            hint="Target Country">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :color="fieldStatusColor('targetCountry')"
                                :loading="loadingField == 'targetCountry'"
                                @click="changeStatus('targetCountry')"><v-icon>{{
                                  fieldStatusIcon('targetCountry')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.targetRegion" persistent-hint
                            hint="Target Region">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :color="fieldStatusColor('targetRegion')"
                                :loading="loadingField == 'targetRegion'" @click="changeStatus('targetRegion')"><v-icon>{{
                                  fieldStatusIcon('targetRegion')
                                }}</v-icon></v-btn>
                            </template></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field readonly disabled solo dense v-model="addendum.exporterName" persistent-hint
                        hint="Name of Exporter"> <template v-slot:append v-if="task.status != 'New'">
                          <v-btn icon :color="fieldStatusColor('exporterName')" :loading="loadingField == 'exporterName'"
                            @click="changeStatus('exporterName')"><v-icon>{{
                              fieldStatusIcon('exporterName')
                            }}</v-icon></v-btn>
                        </template></v-text-field>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="addendum.exportCertificateNumber"
                            persistent-hint hint="Export Certificate number"></v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.carrierReferenceNumber"
                            persistent-hint hint="Booking Ref"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="addendum.vesselName" persistent-hint
                            hint="Vessel">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :color="fieldStatusColor('vesselName')" :loading="loadingField == 'vesselName'"
                                @click="changeStatus('vesselName')"><v-icon>{{
                                  fieldStatusIcon('vesselName')
                                }}</v-icon></v-btn>
                            </template></v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.voyageNumber" persistent-hint
                            hint="Voyage number">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :color="fieldStatusColor('voyageNumber')"
                                :loading="loadingField == 'voyageNumber'" @click="changeStatus('voyageNumber')"><v-icon>{{
                                  fieldStatusIcon('voyageNumber')
                                }}</v-icon></v-btn>
                            </template></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-text-field readonly disabled solo dense v-model="addendum.today" persistent-hint
                        hint="Date"></v-text-field>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="addendum.regimeCode" persistent-hint
                            hint="Regime Code">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :color="fieldStatusColor('regimeCode')" :loading="loadingField == 'regimeCode'"
                                @click="changeStatus('regimeCode')"><v-icon>{{
                                  fieldStatusIcon('regimeCode')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.etd" persistent-hint
                            hint="Departure Date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled hint="Name" persistent-hint solo dense
                            v-model="addendum.userFullName"> <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :color="fieldStatusColor('userFullName')"
                                :loading="loadingField == 'userFullName'" @click="changeStatus('userFullName')"><v-icon>{{
                                  fieldStatusIcon('userFullName')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                          <v-card flat style="background-color: white !important; height: 50px; width: 100%">
                            <v-card-text class="my-0 py-0" style="border-radius: 0px">
                              <v-img v-if="addendum.userSignature" contain height="50"
                                :src="addendum.userSignature"></v-img>
                            </v-card-text>
                          </v-card>

                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card-text>
                <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
                <v-row justify="end" v-if="addendum.sumOfCartons && !loading">
                  <v-col cols="12" sm="4" md="3">
                    <span style="font-size: 14px">
                      Total Cartons: <b> <u>{{ addendum.sumOfCartons }}</u> </b> <v-btn icon
                        :color="fieldStatusColor('sumCartons')" :loading="loadingField == 'sumCartons'"
                        @click="changeStatus('sumCartons')"><v-icon>{{
                          fieldStatusIcon('sumCartons')
                        }}</v-icon></v-btn>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <span style="font-size: 14px">
                      Total Pallets: <b><u>{{ addendum.totalPallets }}</u></b> <v-btn icon
                        :color="fieldStatusColor('totalPallets')" :loading="loadingField == 'totalPallets'"
                        @click="changeStatus('totalPallets')"><v-icon>{{
                          fieldStatusIcon('totalPallets')
                        }}</v-icon></v-btn>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <span style="font-size: 14px">
                      Total Nett Weight: <b><u>{{ addendum.nettWeightSum }} KG</u></b> <v-btn icon
                        :color="fieldStatusColor('nettWeightSum')" :loading="loadingField == 'nettWeightSum'"><v-icon>{{
                          fieldStatusIcon('nettWeightSum')
                        }}</v-icon></v-btn>
                    </span>
                  </v-col>
                </v-row>
                <v-data-table v-if="addendum.pallet && !loading" height="60vh" :item-class="itemRowBackground"
                  :items="addendum.pallet" :headers="palletHeaders" fixed-header item-key="id" dense hide-default-footer
                  disable-pagination hide-default-header>
                  <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header">
                      <tr>
                        <th v-for="header in headers" :key="header.value" scope="col">
                          <v-row v-if="header.value == 'inspectionDate'" align="center">
                            <v-col cols="12">
                              <span>Inspection Date</span>
                              <v-btn v-if="task.status != 'New'" icon :color="fieldStatusColor('inspectionDate')"
                                :loading="loadingField == 'inspectionDate'"><v-icon>{{
                                  fieldStatusIcon('inspectionDate')
                                }}</v-icon></v-btn>
                            </v-col>

                          </v-row>
                          <span v-else>{{ header.text }}</span>
                        </th>
                      </tr>
                    </thead>
                  </template>

                  <template v-slot:[`item.attachments`]="{ item }">
                    <v-tooltip top v-if="item.consignmentNote">
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" outlined v-on="on" small icon @click="viewConsignmentNote(item)">
                          <v-icon small>description</v-icon>
                        </v-btn>
                      </template>
                      <span>Consignment Note</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.permitDocument">
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" outlined v-on="on" small icon @click="viewRemovalPermit(item)">
                          <v-icon small>agriculture</v-icon>
                        </v-btn>
                      </template>
                      <span>Removal Permit: {{ item.permitNo }} expires
                        {{ item.permitValidUntil }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

              </v-card-text>
            </v-col>
          </v-expand-transition>

        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="consignmentNoteModal" :fullscreen="$vuetify.breakpoint.mobile" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn icon @click="(consignmentNoteModal = false), (documentItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <el-container>
            <el-main :key="itemKey" v-loading="documentItem.loadingImage">
              <div style="height: 75vh; overflow-y: auto">
                <div v-if="documentItem.fileType == 'application/pdf'">
                  <pdf ref="pdf" :src="documentItem.file" v-for="page in documentItem.pages" :key="page" :rotate="rotate"
                    :page="page"></pdf>
                </div>

                <v-img v-else-if="documentItem.fileType &&
                  documentItem.fileType.includes('image')
                  " :style="{ transform: `rotate(${rotate}deg)` }" :src="documentItem.file"></v-img>
              </div>
            </el-main>
          </el-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permitModal" :fullscreen="$vuetify.breakpoint.mobile" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            <!-- {{ documentItem.name }} -->
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn icon @click="(permitModal = false), (documentItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <el-container>
            <el-main :key="itemKey" v-loading="documentItem.loadingImage">
              <div style="height: 75vh; overflow-y: auto">
                <div v-if="documentItem.fileType == 'application/pdf'">
                  <pdf ref="pdf" :src="documentItem.file" v-for="page in documentItem.pages" :key="page" :rotate="rotate"
                    :page="page"></pdf>
                </div>

                <v-img v-else-if="documentItem.fileType &&
                  documentItem.fileType.includes('image')
                  " :style="{ transform: `rotate(${rotate}deg)` }" :src="documentItem.file"></v-img>
              </div>
            </el-main>
          </el-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewModal" :fullscreen="$vuetify.breakpoint.mobile || fullscreen" width="1200px" persistent>
      <v-card flat v-if="previewItem && previewItem.fullFile">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon :key="downloadKey" :loading="downloadingFile == previewItem.file"
            @click="downloadDocument(previewItem)"><v-icon>download</v-icon></v-btn>

          <v-btn icon @click="(previewModal = false), (previewItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{
            'max-height': fullscreen ? '88vh' : '80vh',
            'overflow-y': 'auto',
            'background-color': 'var(--v-modal-base) !important',
          }">
          <v-row justify="center">
            <v-col cols="12" md="9" lg="8" xl="8" class="text-center">
              <div ref="pdfBox">
                <pdf style="display: none;" :src="previewItem.fullFile" @num-pages="pageCount = new Array($event)" />
                <pdf v-for="(page, index) in pageCount" :key="index" :src="previewItem.fullFile" :page="index + 1" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rejectionReasonModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Rejection Reason
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="rejectionReasonModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea outlined placeholder="Please leave some comments on the reasons the inspection has been rejected."
            v-model="inspectionResult.comments"></v-textarea>
          <v-row class="mt-1" justify="center">
            <v-col cols="12" class="text-center">
              <v-btn color="green" text :disabled="!inspectionResult.comments" @click="submitInspectionResult">
                Submit Inspection
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import dateFormat from 'dateformat';
import pdf from "vue-pdf";

export default {
  props: ['task'],
  components: {
    pdf
  },
  watch: {
    'task.id': {
      immediate: true,
      handler(val) {
        if (val) {
          // this.getAddendumData(val)
          this.getInspectionResultData(val)
          this.viewTaskInfo = true
        }
      }
    }
  },
  data: () => ({
    addendum: {},
    consignmentNoteModal: false,
    customHeaders: ['noCartons', 'sscc'],
    documentItem: {},
    docLoading: null,
    downloadingFile: null,
    downloadKey: 1000,
    documentKey: 2000,
    fullscreen: false,
    itemKey: 0,
    loadingInspectionResult: false,
    inspectionResult: {},
    inspectionHistory: [],
    loading: false,
    loadingField: null,
    pageCount: [],
    permitModal: false,
    palletHeaders: [
    {
        text: "Line",
        value: "line",
        sortable: false,
        align: "center",
      },
      {
        text: "Attachments",
        value: "attachments",
        sortable: false,
        align: "center",
      },
      {
        text: "Producer/ PUC(s)",
        value: "farm",
        sortable: false,
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        sortable: false,
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        sortable: false,
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        sortable: false,
        align: "center",
      },
      {
        text: "Production Area",
        value: "productionArea",
        sortable: false,
        align: "center",
      },
      {
        text: "Commodity",
        value: "commodity",
        sortable: false,
        align: "center",
      },
      {
        text: "Variety",
        value: "variety",
        sortable: false,
        align: "center",
      },
      {
        text: "Flesh Colour",
        value: "fleshColour",
        sortable: false,
        align: "center",
      },
      {
        text: "Class/ Grade",
        value: "gradeClassCode",
        width: "20px",
        sortable: false,
        align: "center",
      },
      {
        text: "Count",
        value: "countSize",
        width: "20px",
        sortable: false,
        align: "center",
      },
      {
        text: "No. Cartons",
        value: "noCartons",
        sortable: false,
        width: "20px",
        align: "right",
      },
      {
        text: "Nett Weight",
        value: "nettWeight",
        sortable: false,
        align: "right",
      },
      {
        text: "Pallet ID",
        value: "sscc",
        sortable: false,
        align: "center",
      },
      {
        text: "PPECB Inspection Date",
        value: "inspectionDate",
        width: "250px",
        sortable: false,
        align: "center",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        sortable: false,
        align: "center",
      },
      {
        text: "Inspection Point",
        value: "inspectionPoint",
        width: "20px",
        sortable: false,
        align: "center",
      },
    ],
    previewItem: {},
    previewModal: false,
    rejectionReasonModal: false,
    rotate: 0,
    savingInspection: false,
    validationKey: 5000,
    viewTaskInfo: true
  }),
  created() {

  },
  computed: {
    passValidation() {
      let data = this.inspectionResult && this.inspectionResult.taskInspectionResultFields ? this.inspectionResult.taskInspectionResultFields : []
      let result = false
      if (data && data.length > 0) {
        let count = data.filter(x => x.status == true).length
        if (count == 13) {
          result = true
        }
      }
      return result
    },
    validateInspection() {
      let data = this.inspectionResult && this.inspectionResult.taskInspectionResultFields ? this.inspectionResult.taskInspectionResultFields : []
      let result = false
      if (data && data.length > 0) {
        let count = data.filter(x => x.status != null).length
        if (count == 13) {
          result = true
        }
      }
      return result
    }
  },
  methods: {
    async changeStatus(field) {
      if (this.task.status == 'In Progress') {
        this.loadingField = field
        let find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
        if (find) {
          if (find.status == null) {
            find.status = true
            find.time = new Date()
            await this.$API.updateInspectionField(find)
          } else if (find.status == true) {
            find.status = false
            find.time = new Date()
            await this.$API.updateInspectionField(find)
          } else {
            find.status = null
            find.time = new Date()
            await this.$API.updateInspectionField(find)
          }
        } else {
          let result = await this.$API.createInspectionField({
            field: field,
            status: true,
            time: new Date(),
            taskInspectionResultId: this.inspectionResult.id
          })
          this.inspectionResult.taskInspectionResultFields.push(result)
        }
        await this.$API.updateInspectionResult({
          id: this.inspectionResult.id,
          inspectedById: this.$store.state.user.id
        })
        this.loadingField = null
        this.validationKey++
      }
    },
    async fieldStatus(field) {
      let find = null
      if (this.inspectionResult && this.inspectionResult.taskInspectionResultFields) {
        find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
      }
      let value = null
      if (find) {
        value = find.status
      }
      switch (value) {
        case true:
          return { color: 'green', icon: 'task_alt' }
        case false:
          return { color: 'red', icon: 'cancel' }
        default:
          return { color: 'orange', icon: 'error' }
      }
    },
    fieldFriendlyName(field) {
      switch (field) {
        case 'portOfLoadCode':
          return 'Port of Load'
        case 'portOfDischargeCode':
          return 'Port of Entry'
        case 'targetCountry':
          return 'Target Country'
        case 'targetRegion':
          return 'Target Region'
        case 'vesselName':
          return 'Vessel'
        case 'voyageNumber':
          return 'Voyage Number'
        case 'regimeCode':
          return 'Regime Code'
        case 'exporterName':
          return 'Exporter Name'
        case 'inspectionDate':
          return 'Inspection Date'
        case 'userFullName':
          return 'User Name'
        case 'totalPallets':
          return 'Total Pallets'
        case 'nettWeightSum':
          return 'Nett Weight'
        case 'sumCartons':
          return 'Total Cartons'
        default:
          return field
      }
    },
    fieldStatusColor(field) {
      let find = null
      if (this.inspectionResult && this.inspectionResult.taskInspectionResultFields) {
        find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
      }
      let value = null
      if (find) {
        value = find.status
      }
      switch (value) {
        case true:
          return 'green'
        case false:
          return 'red'
        default:
          return 'orange'
      }
    },
    fieldStatusIcon(field) {
      let find = null
      if (this.inspectionResult && this.inspectionResult.taskInspectionResultFields) {
        find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
      }
      let value = null
      if (find) {
        value = find.status
      }
      switch (value) {
        case true:
          return 'task_alt'
        case false:
          return 'cancel'
        default:
          return 'error'
      }
    },
    customHeader(header) {
      switch (header.value) {
        case 'noCartons':
          return 'No. of Cartons<br/> ' + this.addendum.sumOfCartons
        case 'sscc':
          return 'Pallet ID <br/> ' + this.addendum.totalPallets
      }

    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "dd/mm/yyyy HH:MM");
      }
      return result;
    },
    // async getAddendumData(id) {
    //   this.loading = true
    //   this.addendum = await this.$API.getAddendumData(id)
    //   this.loading = false
    // },
    getIcon(name) {
      if (name) {
        let type = name.split(".");
        let result = null;
        if (type.length > 0) {
          result = type.at(-1).toLowerCase();
        }
        if (result && result.toLowerCase() == "xlsx") {
          result = "xls";
        }
        return `/icons/${result}.svg`;
      }
      return null

    },
    async getInspectionResultData(id) {
      this.loadingInspectionResult = true
      this.loading = true
      this.inspectionResult = await this.$API.getInspectionResultData(id)
      let inspectionData = await axios.get(this.inspectionResult.inspectionDataURL)
      this.addendum = inspectionData.data
      if(this.addendum.pallet && this.addendum.pallet[0]){
        let keys = Object.keys(this.addendum.pallet[0])
        if(!keys.includes('audit')){
          await this.addendum.pallet.forEach((x, i) => {
            x.audit = this.isAuditLine(x, i)
          }
          )
        }
      }
      this.inspectionResult.inspectionData = this.addendum
      // this.addendum = this.inspectionResult.inspectionData
      this.loading = false
      this.inspectionHistory = await this.$API.getAllInspectionResults(id)
      this.loadingInspectionResult = false
    },
    getTaskStatus(task) {
      switch (task.status) {
        case "Pending":
          return { name: task.status, icon: "schedule", color: "orange" };
        case "In Progress":
          return { name: task.status, icon: "pending", color: "blue" };
        case "Rejected":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Complete":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Success":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: task.status, icon: "cancel", color: "grey" };
        case "Error":
          return { name: task.status, icon: "error", color: "red" };
        case "Not Created":
          return { name: task.status, icon: "error", color: "red" };
        case "Failed":
          return { name: task.status, icon: "cancel", color: "red" };
        case "File Generated":
          return { name: task.status, icon: "task_alt", color: "orange" };
        case "Draft":
          return { name: task.status, icon: "edit_note", color: "orange" };
        case "Submitted":
          return { name: task.status, icon: "mark_email_read", color: "blue" };
        case "Not Required":
          return { name: task.status, icon: "close", color: "grey" };
        case "Approved":
          return { name: task.status, icon: "fact_check", color: "green" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: task.status,
          };
      }
    },
    isAuditLine(item, index) {
      if (!this.addendum.breakbulk) {
        let containers = [...new Set(this.addendum.pallet.map(x => x.containerNo))]
        if (containers.length > 1) {
          let palletIndex = this.addendum.pallet.filter(x => x.containerNo == item.containerNo).findIndex(x => x.id == item.id)
          return this.addendum.auditLines.includes(palletIndex + 1)

        } else {
          return this.addendum.auditLines.includes(index + 1)
        }
      } else {
        let uniquePallets = [...new Set(this.addendum.pallet.map((x) => x.sscc))]

        let totalPallets = uniquePallets.length
        let auditLine = this.addendum.auditLines[0]
        let auditedPallets = Math.ceil(totalPallets / auditLine)
        let indices = []
        // console.log('Audited pallets', auditedPallets)
        for (let j = 1; j <= auditedPallets; j++) {
          let itemIndex = (j * auditLine)
          if (j == 1 && itemIndex > totalPallets) {
            if (itemIndex > totalPallets) {
              itemIndex = totalPallets
            }
          }
          if (j > 1) {
            itemIndex = (auditLine + ((j - 1) * 50))
          }
          let findPallet = uniquePallets[itemIndex - 1]
          if (findPallet) {
            // console.log('Audit pallet', uniquePallets[itemIndex - 1], itemIndex, uniquePallets)
            itemIndex = this.addendum.pallet.map((x) => x.sscc).lastIndexOf(findPallet)
            itemIndex += 1
            if (itemIndex <= totalPallets) {
              indices.push(itemIndex)
            }
          }
          if(!indices.includes(totalPallets)){
            indices.push(totalPallets)
          }
        }
        // console.log(indices)
        return indices.includes(index + 1)
      }

    },
    itemRowBackground(item) {
      // let index = this.addendum.pallet.findIndex(x => x.id == item.id)
      // if (this.isAuditLine(item, index)) {
      //   return 'myclass'
      // }
      if(item.audit){
        return 'myclass'
      }
    },
    async previewDocument(doc) {
      this.docLoading = doc.file;
      this.documentKey++
      this.previewItem = doc
      if (!this.previewItem) {
        this.previewItem = {}
      }
      let file = await this.$API.downloadFile({
        key: doc.file
      });
      this.previewItem.fullFile = file.file;
      this.previewItem.fileType = file.fileType;
      this.previewModal = true
      this.docLoading = null
      this.documentKey++
    },
    submitInspection(task) {
      task.status = this.passValidation ? 'Approved' : 'Rejected'
      this.inspectionResult.status = task.status
      if (task.status == 'Rejected') {
        this.rejectionReasonModal = true
      } else {
        this.submitInspectionResult()
      }
    },
    async submitInspectionResult() {
      this.savingInspection = true
      this.rejectionReasonModal = false
      this.inspectionResult.end = new Date()
      this.inspectionResult.inspectedById = this.$store.state.user.id
      this.task.inspectedById = this.$store.state.user.id
      await this.$API.submitFinalInspectionResult(this.inspectionResult)
      await this.$API.updateInspectionTask(this.task)
      this.$emit('update', this.task)
      this.$emit('close')
      this.savingInspection = false

    },
    async downloadDocument(doc) {
      this.downloadingFile = doc.file
      this.downloadKey++;
      let file = await this.$API.downloadFile({
        key: doc.file
      });
      doc.fullFile = file.file;
      doc.fileType = file.fileType;
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.fullFile;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      this.downloadingFile = null;
      this.downloadKey++;
    },
    async startTask(task) {
      this.task.status = 'In Progress'
      this.task.startedById = this.$store.state.user.id
      this.task.startedBy = {
        id: this.$store.state.user.id,
        firstName: this.$store.state.user.firstName,
        surname: this.$store.state.user.surname,
        emailAddress: this.$store.state.user.emailAddress
      }
      await this.$API.updateTask(task)
      this.inspectionResult.status = 'In Progress'
      this.inspectionResult.start = new Date()
      this.inspectionResult.startedById = this.$store.state.user.id
      this.inspectionResult.startedBy = {
        id: this.$store.state.user.id,
        firstName: this.$store.state.user.firstName,
        surname: this.$store.state.user.surname,
        emailAddress: this.$store.state.user.emailAddress
      }
      this.inspectionHistory.push(this.inspectionResult)


      await this.$API.updateInspectionResult({ id: this.inspectionResult.id, status: 'In Progress', startedById: this.$store.state.user.id, start: new Date() })
    },
    async viewConsignmentNote(item) {
      this.documentItem = item.consignmentNote;
      this.documentItem.loadingImage = true;
      this.consignmentNoteModal = true;
      let file = await this.$API.downloadFile({
        key: item.consignmentNote.key,
      });
      this.documentItem.name = item.sscc;
      this.documentItem.file = file.file;
      this.documentItem.fileType = file.fileType;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    async viewRemovalPermit(item) {
      this.documentItem = item.permitDocument;
      this.documentItem.loadingImage = true;
      this.permitModal = true;
      let file = await this.$API.downloadFile({
        key: item.permitDocument.key,
      });
      this.documentItem.name = item.sscc;
      this.documentItem.file = file.file;
      this.documentItem.fileType = file.fileType;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
  }
}
</script>
<style lang="css">
.myclass {
  color: orange;
}

.slide-nav-leave-active {
  transition: 0.5s;
}

.slide-nav-enter-active {
  transition: 0.8s;
}

.slide-nav-enter {
  transform: translateX(-100%);
}

.slide-nav-leave-to {
  transform: translateX(-100%);
}

.slide-marker-leave-active {
  transition: 0.1s;
}

.slide-marker-enter-active {
  transition: 1s;
}

.slide-marker-enter {
  transform: translateX(-100%);
}

.slide-marker-leave-to {
  transform: translateX(-100%);
}
</style>